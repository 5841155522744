import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { ToastContainer, Zoom } from 'react-toastify'
import { CookiesProvider } from 'react-cookie'
import ReactGA from 'react-ga4'

import store from './store'
import Routes from './routes'
import { defaultToastSeconds } from './constant'
import { googleAnalyticsId } from './config'

import 'react-toastify/dist/ReactToastify.css'
import './assets/styles/index.css'

ReactGA.initialize(googleAnalyticsId)

ReactDOM.render(
  <ReduxProvider store={store}>
    <StrictMode>
      <CookiesProvider>
        <Routes />
      </CookiesProvider>
      <ToastContainer
        position="bottom-right"
        transition={Zoom}
        autoClose={defaultToastSeconds * 1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable={true}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
      />
    </StrictMode>
  </ReduxProvider>,
  document.getElementById('root'),
)
