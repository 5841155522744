import { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router'
import { useCookies } from 'react-cookie'
import { programs } from '@metaplex/js'

import Loading from '../../../components/Loading'

import { ApiURL } from '../../../config'
import { web3Connection } from '../../../utils/web3'
import { SESSION_COOKIE_NAME } from '../../../constant'

import styles from './Collection.module.css'

const Metadata = programs.metadata.Metadata
const MetadataData = programs.metadata.MetadataData

const NftItem = ({ nft }) => {
  return (
    <>
      <div className="relative p-2 bg-zinc-800 rounded-[24px]">
        <div className="max-h-[200px] max-w-[200px] aspect-square mb-2 rounded-[28px]">
          <img
            src={nft.image}
            alt={nft.name}
            className="w-full h-auto rounded-[20px]"
            style={{ width: '100%', height: '100%' }}
          />
        </div>
        <div className="px-2">
          <div className="truncate text-white">{nft.name}</div>
        </div>
      </div>
    </>
  )
}

const Collection = () => {
  const { mint } = useParams()

  const [isLoading, setIsLoading] = useState(true)
  const [mints, setMints] = useState([])
  const [nfts, setNfts] = useState([])
  const [nftsMeta, setNftsMeta] = useState({})
  const [collectionMeta, setCollectionMeta] = useState({})
  const [cookies] = useCookies([SESSION_COOKIE_NAME])

  const getCollectionMembers = useCallback(
    (e) => {
      fetch(`${ApiURL}nfts/collections/${mint}/members`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookies[SESSION_COOKIE_NAME]}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setMints(res)
          if (res.length === 0) {
            setIsLoading(false)
          }
        })
    },
    [mint, cookies],
  )

  useEffect(() => {
    if (Object.keys(nftsMeta).length === mints.length && mints.length > 0 && isLoading) {
      mints.forEach((mint) => {
        const nft = {
          mint: mint,
          name: nftsMeta[mint].name,
          image: nftsMeta[mint].image,
        }
        setNfts((prev) => [...prev, nft])
      })
    }
  }, [nftsMeta, mints, isLoading])

  const fetchTokenMetadata = useCallback(
    async (mintPubkey) => {
      const pda = await Metadata.getPDA(mintPubkey)
      const account = await web3Connection.getAccountInfo(pda)
      if (!account) return
      const metadata = MetadataData.deserialize(account.data)
      let meta
      if (metadata.data.uri) {
        try {
          const offchainMeta = await fetch(metadata.data.uri)
          if (offchainMeta.status === 200) {
            meta = await offchainMeta.json()
          }
        } catch (e) {
          console.log(e)
        }
      }
      if (!meta && metadata.data.name) {
        meta = {
          name: metadata.data.name,
          image: '',
        }
      }
      if (meta) {
        if (mintPubkey === mint) {
          setCollectionMeta(meta)
        } else {
          setNftsMeta((prev) => ({ ...prev, [mintPubkey]: meta }))
        }
      }
    },
    [mint],
  )

  const fetchMetadata = useCallback(() => {
    mints.forEach((mint) => {
      fetchTokenMetadata(mint)
    })
  }, [mints, fetchTokenMetadata])

  useEffect(() => {
    getCollectionMembers()
    fetchTokenMetadata(mint)
  }, [mint, fetchTokenMetadata, getCollectionMembers])

  useEffect(() => {
    if (mints.length > 0) {
      fetchMetadata()
    }
  }, [mints, fetchMetadata])

  useEffect(() => {
    if (nfts.length > 0) {
      setIsLoading(false)
    }
  }, [nfts])

  const collectionName = collectionMeta.name ? collectionMeta.name : 'NFT Collection'

  return (
    <div className={`flex w-full h-full flex-col items-center pt-24 ${styles.container}`}>
      <div className="fade-focus-in flex w-full flex-col">
        {isLoading ? (
          <div className="flex w-full h-10 justify-center">
            <Loading />
          </div>
        ) : (
          <div className="min-h-full h-full">
            <div className="font-bold text-2xl mb-4">{collectionName}</div>
            <div className="text-zinc-400 mb-8">
              Here are the NFTs that belong to this collection
            </div>
            <div className="grid grid-cols-2 gap-y-3 gap-x-2.5 max-w-[414px]">
              {nfts.length === 0 && (
                <p className="w-full pt-4 pb-3 text-center text-44 font-black text-gray-4b4b4b">
                  None
                </p>
              )}
              {nfts.length > 0 && (
                <>
                  {nfts.map((nft) => (
                    <Link to={`/nfts/details/${nft.mint}`} key={nft.mint}>
                      <NftItem key={`nft-${nft.mint}`} nft={nft} />
                    </Link>
                  ))}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Collection
