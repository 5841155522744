import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Loading from '../../../components/Loading'
import NftCard from '../../../components/NftCard'

import { useGetNftsQuery } from '../../../services/nftsApi'

import styles from './Nfts.module.css'

const NftsPage = () => {
  const [page, setPage] = useState(undefined)
  const [nfts, setNfts] = useState([])
  const { data } = useGetNftsQuery(page)

  useEffect(() => {
    if (data && data.results) {
      setNfts((nfts) => [...nfts, ...data.results])
    }
  }, [data])

  const nextPage = () => setPage(data?.page.after)

  return (
    <div className={`flex w-full h-full flex-col items-center pt-24 ${styles.container}`}>
      <div className="fade-focus-in flex w-full flex-col">
        {!data ? (
          <div className="flex w-full h-10 justify-center">
            <Loading />
          </div>
        ) : (
          <>
            <div className="font-bold text-2xl mb-4">NFT Marketplace</div>
            <div className="text-zinc-400 mb-8">Here are the NFTs being offered</div>
            <div className="grid grid-cols-2 gap-y-3 gap-x-2.5 max-w-[414px]">
              {data.results.length === 0 && (
                <p className="w-full pt-4 pb-3 text-center text-44 font-black text-gray-4b4b4b">
                  None
                </p>
              )}
              {nfts.length > 0 && (
                <>
                  {nfts.map((nft) => (
                    <Link to={`/nfts/${nft.id}`} key={nft.mintPublicKey}>
                      <NftCard key={`nft-${nft.mintPublicKey}-${nft.id}`} nft={nft} />
                    </Link>
                  ))}
                </>
              )}
            </div>
            {data?.page.hasNextPage && (
              <div className="flex w-full h-15 justify-center pb-20">
                <button
                  className="w-1/3 rounded-md px-5 py-3 mt-2 font-medium text-white bg-[#209BF0]"
                  onClick={nextPage}
                >
                  Next
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default NftsPage
