import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { baseApi } from '../services/baseApi'
import { priceApi, birdeyePriceApi } from '../services/priceApi'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch()
export const useAppSelector = useSelector
export const useAppStore = useStore

const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    [priceApi.reducerPath]: priceApi.reducer,
    [birdeyePriceApi.reducerPath]: birdeyePriceApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(process.env.NODE_ENV === 'development' ? [] : [])
      .concat(baseApi.middleware)
      .concat(priceApi.middleware)
      .concat(birdeyePriceApi.middleware),
})

export default store
