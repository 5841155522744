import { useState, useCallback } from 'react'
import { Link } from 'react-router-dom'

import { useHistory } from 'react-router'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'

import NavMenu from '../../components/NavMenu'
import { navRoutes } from '../../constant'
import Logo from '../../assets/icons/Logo.svg'

export function Search() {
  const history = useHistory()
  const [twitterUsername, setTwitterUsername] = useState('')

  const handleSearch = useCallback(
    (e) => {
      e.preventDefault()
      if (twitterUsername) history.push(`/${twitterUsername}`)
    },
    [history, twitterUsername],
  )

  return (
    <form className="mt-6 w-full lg:max-w-sm mx-auto" onSubmit={handleSearch}>
      <div className="relative mt-1 rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center px-5">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type="search"
          name="search"
          id="search"
          className="block w-full rounded-full pl-12 pr-4 focus:border-green-500 focus:ring-green-500 sm:text-sm py-3 text-black"
          placeholder="Search any @username"
          value={twitterUsername}
          onChange={(e) => setTwitterUsername(e.target.value)}
        />
      </div>
    </form>
  )
}

const LayoutHeader = () => {
  const { length: historyLength } = useHistory()

  return (
    <div className="w-full mx-auto text-white">
      <div className={`${historyLength === 1 ? 'fade-focus-in' : ''} pt-4`}>
        <div className="flex justify-between items-center text-white">
          <Link to="/" className="flex items-center">
            <img className="w-10 h-10" src={Logo} alt="" />
            <span className="min-w-max font-bold text-2xl ml-1 pt-1">Hey Wallet</span>
          </Link>
          <NavMenu routes={navRoutes} />
        </div>
      </div>
    </div>
  )
}

export default LayoutHeader
