import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ApiURL } from '../config'

const baseAPIURL = ApiURL.slice(-1) === '/' ? ApiURL : ApiURL + '/'

const tagTypes = ['User', 'KeyPair']

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: baseAPIURL }),
  endpoints: () => ({}),
  tagTypes,
})
