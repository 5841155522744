import { baseApi } from './baseApi'

export const userApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query({
      query: (username) => ({
        method: 'get',
        url: `/users/${username}`,
        // body: { twitter_handle: input },
      }),
      providesTags: (result, error) =>
        error
          ? []
          : [
              { type: 'User', id: result?.username },
              { type: 'User', id: 'me' },
            ],
    }),
  }),
  overrideExisting: false,
})

export const { useGetUserQuery } = userApi
