import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import { ApiURL } from '../config'
import { SESSION_COOKIE_NAME } from '../constant'
import MainLayout from '../layouts/main/MainLayout'
import GeneralLayout from '../layouts/general/GeneralLayout'
import authLayoutRoutes from './authLayoutRoutes'
import mainLayoutRoutes from './mainLayoutRoutes'
import generalLayoutRoutes from './generalLayoutRoutes'
import Page404 from '../pages/_error/_404'
import Oauth from '../pages/main/Oauth'
import AuthContext from '../context/AuthContext'
import { isHwHost, bidPath } from '../utils/common'

const GUEST_PATHS = ['/', '/login']

const RenderChildRoutes = (Layout, routes, currentUser, cookiePresent, finishedLoading) => {
  return routes.map(
    ({ path, Component, children, redirect, redirectExternal, authenticated }, index) => {
      return children ? (
        RenderChildRoutes(Layout, children, currentUser, cookiePresent, finishedLoading)
      ) : redirectExternal ? (
        <Route
          key={index}
          path={path}
          exact
          render={() => {
            window.location.href = redirectExternal
            return null
          }}
        />
      ) : redirect ? (
        <Route
          key={index}
          path={path}
          exact
          render={(props) =>
            props.match.params?.id ? (
              <Redirect to={`/${props.match.params?.id}`} exact />
            ) : (
              <Redirect to={redirect} exact />
            )
          }
        />
      ) : Component ? (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => {
            const isUserSignedIn = currentUser && !!currentUser.username
            if (authenticated) {
              if (!finishedLoading && !isUserSignedIn) {
                return null
              } else if (isUserSignedIn) {
                return <Layout>{Component ? <Component /> : <></>}</Layout>
              } else {
                if (!(finishedLoading && cookiePresent)) {
                  return <Redirect to={`/login?prevPath=${window.location.pathname}`} exact />
                }
              }
            } else {
              if (GUEST_PATHS.includes(props.location.pathname) && isUserSignedIn) {
                const dest =
                  props.location.pathname === bidPath('/login')
                    ? bidPath('/')
                    : `/${currentUser.username}`
                return <Redirect to={dest} exact />
              } else {
                return <Layout>{Component ? <Component /> : <></>}</Layout>
              }
            }
          }}
        />
      ) : (
        <></>
      )
    },
  )
}

const Routes = () => {
  const [currentUser, setCurrentUser] = useState(null)
  const [cookies] = useCookies([SESSION_COOKIE_NAME])
  const [finishedLoading, setFinishedLoading] = useState(false)
  const cookiePresent = !!cookies[SESSION_COOKIE_NAME]
  const isHeyWalletDomain = isHwHost()

  useEffect(() => {
    if (cookies[SESSION_COOKIE_NAME]) {
      fetch(`${ApiURL}users`, {
        headers: { Authorization: `Bearer ${cookies[SESSION_COOKIE_NAME]}` },
      })
        .then((res) => {
          return res.json()
        })
        .then((res) => {
          if (res) {
            setCurrentUser(res)
            setFinishedLoading(true)
          }
        })
    } else {
      setFinishedLoading(true)
    }
  }, [cookies])

  return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser }}>
      <Router>
        <Switch>
          <Route
            path="/wallet"
            key="auth"
            exact
            render={() => {
              const isUserSignedIn = currentUser && !!currentUser.username
              if (!finishedLoading && !isUserSignedIn) {
                return null
              } else if (isUserSignedIn) {
                return <Redirect to={`/${currentUser.username}`} exact />
              } else {
                if (!(finishedLoading && cookiePresent)) {
                  return <Redirect to={`/login?prevPath=${window.location.pathname}`} exact />
                }
              }
            }}
          ></Route>
          <Route path="/oauth" key="auth" exact render={() => <Oauth />}></Route>

          {isHeyWalletDomain &&
            RenderChildRoutes(
              GeneralLayout,
              authLayoutRoutes,
              currentUser,
              cookiePresent,
              finishedLoading,
            )}
          {isHeyWalletDomain &&
            RenderChildRoutes(
              GeneralLayout,
              generalLayoutRoutes,
              currentUser,
              cookiePresent,
              finishedLoading,
            )}
          {isHeyWalletDomain &&
            RenderChildRoutes(
              MainLayout,
              mainLayoutRoutes,
              currentUser,
              cookiePresent,
              finishedLoading,
            )}

          <Route
            render={() => (
              <GeneralLayout>
                <Page404 />
              </GeneralLayout>
            )}
          />
        </Switch>
      </Router>
    </AuthContext.Provider>
  )
}

export default Routes
