import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const ErrorDisplay = ({ error, onRetry, children }) => {
  const { push } = useHistory()

  const errorMessage = useMemo(() => {
    if (error) {
      if ('data' in error) {
        const errorData = error.data
        return errorData.errors[0]?.message || ''
      } else if ('message' in error) {
        return error.message || ''
      } else if ('error' in error) {
        return 'Network connection lost. Please try again.'
      }
    }

    return ''
  }, [error])

  const onRetryHandler = useCallback(() => {
    if (onRetry) onRetry(error)
    else if (error) {
      if ('status' in error) {
        // error is FetchBaseQueryError
        switch (error.status) {
          case 400:
            push('/')
            return

          default:
            return
        }
      } else if ('code' in error) {
        // error is SerializedError
        switch (error.code) {
          case '404':
            push('/')
            return

          default:
            return
        }
      }
    }
  }, [error, onRetry, push])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Error - Hey Wallet</title>
      </Helmet>
      <div className="flex flex-col items-center justify-center border border-red-400">
        {children ? (
          children
        ) : (
          <>
            <div className="font-colfax mb-4 px-5 py-10 text-center text-3xl font-black leading-none tracking-tight text-white ">
              {errorMessage || 'Something went wrong'}
            </div>
            <button className="px-2 py-4 text-white underline" onClick={onRetryHandler}>
              Retry
            </button>
          </>
        )}
      </div>
    </>
  )
}

export default ErrorDisplay
