import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { animated, useSpring } from '@react-spring/web'

import TokensList from '../TokensList'
import NftList from '../NftList'
import TransactionsList from '../TransactionsList'
import { objectKeys } from '../../utils/common'

export const CurrentTab = {
  tokens: 'Tokens',
  NFTs: 'NFTs',
  transactions: 'Transactions',
}

const TabButton = ({ title, currentTab, onClick }) => (
  <li className={`mb-2`}>
    <button
      className={`py-1 ${currentTab === title ? 'text-white' : 'text-gray-707070'}`}
      style={{
        fontSize: 20,
        paddingRight: 24,
      }}
      onClick={onClick}
    >
      {title}
    </button>
  </li>
)

const TabUnderline = ({ currentTab }) => {
  const underlineStyle = useSpring({
    left:
      currentTab === CurrentTab.tokens
        ? 0
        : currentTab === CurrentTab.NFTs
          ? 89
          : currentTab === CurrentTab.transactions
            ? 158
            : 0,
    width:
      currentTab === CurrentTab.tokens
        ? 64
        : currentTab === CurrentTab.NFTs
          ? 46
          : currentTab === CurrentTab.transactions
            ? 119
            : 64,
  })

  return (
    <animated.div
      className="absolute bottom-0 h-2 border-b border-green-00ffad"
      style={underlineStyle}
    />
  )
}

const UserMainTabs = ({
  user,
  sortedAssets,
  nfts,
  nftsMetadata,
  transactions,
  refreshTokens,
  refreshBalance,
  refreshNfts,
  refreshTransactions,
  setTransactionPageUrl,
  transactionsLoading,
}) => {
  const targetTab = useLocation().pathname.split('/')[2]
  const [currentTab, setCurrentTab] = useState(CurrentTab[targetTab] || CurrentTab.tokens)

  const OpenTab = () => {
    switch (currentTab) {
      case CurrentTab.tokens:
        return <TokensList sortedAssets={sortedAssets} />

      case CurrentTab.NFTs:
        return <NftList nfts={nfts} nftsMetadata={nftsMetadata} />
      case CurrentTab.transactions:
        return (
          <TransactionsList
            user={user}
            transactions={transactions}
            setTransactionPageUrl={setTransactionPageUrl}
            transactionsLoading={transactionsLoading}
          />
        )

      default:
        return null
    }
  }

  return (
    <>
      <div className="my-8 w-full self-start border-b border-gray-3d3d3d">
        <ul className="relative flex">
          {objectKeys(CurrentTab).map((tabKey) => (
            <TabButton
              key={`tab-${tabKey}`}
              title={CurrentTab[tabKey]}
              currentTab={currentTab}
              onClick={() => {
                setCurrentTab(CurrentTab[tabKey])
                // Use window.history.replaceState to prevent page reload
                if (tabKey === 'tokens') {
                  window.history.replaceState(null, '', `/@${user.username}`)
                  refreshTokens()
                  refreshBalance()
                } else if (tabKey === 'NFTs') {
                  window.history.replaceState(null, '', `/@${user.username}/NFTs`)
                  refreshNfts()
                } else if (tabKey === 'transactions') {
                  window.history.replaceState(null, '', `/@${user.username}/${tabKey}`)
                  refreshTransactions()
                }
              }}
            />
          ))}
          <TabUnderline currentTab={currentTab} />
        </ul>
      </div>
      {OpenTab()}
    </>
  )
}

export default UserMainTabs
