import { toast } from 'react-toastify'

import { initialOfflineServices, offlineRetryInterval, ServiceNames } from '../constant'

/**
 * All services are assumed to be initially online, so all properties are false.
 */
export const hasDisconnected = { ...initialOfflineServices }
/**
 * Holds references to the disconnected service toast so they can be dismissed
 * if the connection is restored. Only one toast is allowed per service.
 */
const disconnectedToastId = Object.fromEntries(
  Object.entries(ServiceNames).map(([, value]) => [value, '']),
)
/**
 * Only allow one connection resumed toast per service.
 */
const resumedServiceActiveToast = Object.fromEntries(
  Object.entries(ServiceNames).map(([, value]) => [value, '']),
)

const connectionLostToast = {
  autoClose: false,
  draggable: false,
  closeOnClick: false,
  closeButton: false,
  style: {
    cursor: 'default',
  },
}

const connectionResumedToast = {
  autoClose: 5000,
}

const toggleOfflineToasts = (isServiceOffline, serviceName, setOfflineServices) => {
  if (isServiceOffline) {
    if (disconnectedToastId[serviceName] === '') {
      disconnectedToastId[serviceName] = toast.warn(
        `Connection with the ${serviceName} lost. Attempting to reconnect every ${offlineRetryInterval} seconds...`,
        connectionLostToast,
      )
      // We don't want to show a toast if they initially load the page successfully, but if they
      // disconnect at any point, then we show a message when their connection resumes.
      // So, hasDisconnected is set to true here:
      hasDisconnected[serviceName] = true
    }
    // And checked here before showing a 'connection restored' toast:
  } else if (hasDisconnected[serviceName] && resumedServiceActiveToast[serviceName] === '') {
    toast.dismiss(disconnectedToastId[serviceName])
    disconnectedToastId[serviceName] = ''
    resumedServiceActiveToast[serviceName] = toast.success(
      `Connection with the ${serviceName} restored!`,
      connectionResumedToast,
    )
    setTimeout(
      () => (resumedServiceActiveToast[serviceName] = ''),
      connectionResumedToast.autoClose,
    )
    setOfflineServices &&
      setOfflineServices((state) => ({
        ...state,
        [serviceName]: false,
      }))
  }
}

export default toggleOfflineToasts
