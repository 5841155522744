import ProfileImage from '../ProfileImage'
import { formatNumber, justifyNumberString } from '../../utils/common'
import verifiedBadge from '../../assets/icons/verified.svg'

const UserHeader = ({ user, userBudgetUSD }) => {
  const totalBalance =
    userBudgetUSD === '--' ? '--' : formatNumber(justifyNumberString(userBudgetUSD), 2)

  return (
    <div className="flex w-full flex-col items-center justify-between md:flex-row">
      <div className="flex flex-col items-center md:flex-row">
        {/* Profile Image */}
        {user.profileImageUrl && (
          <div className="overflow-hidden rounded-full shadow-mdLg md:mr-4">
            <ProfileImage width={160} src={user.profileImageUrl} />
          </div>
        )}

        <div className="mt-5 flex flex-col items-center md:items-start md:mt-0 md:mr-4">
          {/* Name */}
          {user.name && (
            <h2 className="text-center font-colfax text-3xl font-bold text-gray-50 md:text-left">
              {user.name}
            </h2>
          )}

          {/* Username */}
          <h3 className="font-colfax mt-1 flex items-center gap-2 text-center text-2xl font-light text-gray-969696 md:text-left">
            @{user.username}
            {user.claimed && <img src={verifiedBadge} alt="verified" width="25" />}
            {user.number ? (
              <>
                <span style={{ marginRight: '-0.45rem' }}>#</span>
                <span className="tracking-tighter" title="User Number">
                  {formatNumber(user.number)}
                </span>
              </>
            ) : null}
          </h3>
        </div>
      </div>

      {/* Wallet Total Balance */}
      <div className="flex flex-col items-center md:items-end">
        <p className="font-colfax text-50 font-bold text-green-00ffad">{`$${totalBalance}`}</p>
      </div>
    </div>
  )
}

export default UserHeader
