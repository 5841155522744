import { PublicKey } from '@solana/web3.js'
import { web3Connection } from '../../../utils/web3'
import { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { Helmet } from 'react-helmet'
import clsx from 'clsx'

import AuthContext from '../../../context/AuthContext'
import Loading from '../../../components/Loading'
import WalletAddress from '../../../components/WalletAddress'

import { ApiURL, telegramBotUsername } from '../../../config'
import { MIN_BALANCE_FOR_SPL, SESSION_COOKIE_NAME } from '../../../constant'
import { amountToLamports } from '../../../utils/common'

import styles from './ClaimReward.module.css'
import btnStyles from '../../../components/TweetButton/TweetButton.module.css'
import ClaimOnTelegramImage from '../../../assets/misc/claim-on-telegram.png'
import twitterBtnStyles from '../../../components/TweetButton/TweetButton.module.css'

const minLamports = parseInt(amountToLamports(MIN_BALANCE_FOR_SPL, 9).toFixed(), 10)

const ClaimRewardPage = () => {
  const history = useHistory()
  const { currentUser } = useContext(AuthContext)
  const [cookies] = useCookies([SESSION_COOKIE_NAME])
  const { ticker, id } = useParams()

  const [hasEnoughSol, setHasEnoughSol] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isClaimLoading, setIsClaimLoading] = useState(false)
  const [isClaimed, setIsClaimed] = useState(false)
  const [redirectToTg] = useState(true) // Toggle if users should be redirected to Telegram to claim

  const acceptReward = () => {
    setIsClaimLoading(true)
    fetch(`${ApiURL}rewards/${ticker.toLowerCase()}/claim`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies[SESSION_COOKIE_NAME]}`,
      },
      method: 'POST',
    }).then((res) => {
      if (res.ok) {
        setIsClaimed(true)
      }
    })
  }

  useEffect(() => {
    web3Connection.getAccountInfo(new PublicKey(id)).then((res) => {
      setIsLoading(false)
      if (res && res.lamports >= minLamports) {
        setHasEnoughSol(true)
      }
    })
  }, [id])

  return (
    <div className={`flex w-full h-full flex-col items-center mx-5 pt-24 ${styles.container}`}>
      {isLoading ? (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="description" content="Loading Hey Wallet" />
            <title>Hey Wallet!</title>
          </Helmet>
          <Loading width="100" screenCentered />
        </>
      ) : (
        <>
          <Helmet defer={false}>
            <meta charSet="utf-8" />
            <title>Accept {ticker} reward - Hey Wallet</title>
          </Helmet>

          <div className="pt-20 flex flex-col items-center">
            {redirectToTg ? (
              <div className="pt-20 flex flex-col items-center">
                <div className="w-90% sm:w-1/2 mb-20">
                  <div className="mb-10 text-center">
                    <h1 className="font-colfax text-center text-56 font-black leading-none tracking-tight text-white">
                      Claiming is done on Telegram.
                    </h1>
                    <br />
                    <br />
                    Open Hey Wallet on Telegram and tap "Claim Tokens"
                  </div>
                  <img src={ClaimOnTelegramImage} alt="" />
                  <a
                    href={`https://t.me/${telegramBotUsername}`}
                    target="_blank"
                    rel="noreferrer"
                    className={`flex items-center justify-center rounded-70px py-5 px-20 md:px-100px ${twitterBtnStyles.flashyButton} z-0 mt-10 font-bold`}
                  >
                    Claim On Telegram
                  </a>
                </div>
              </div>
            ) : (
              <>
                {isClaimed ? (
                  <div className="flex flex-col pb-10">
                    <div>
                      <h1 className="font-colfax text-center text-56 font-black leading-none tracking-tight text-white">
                        The reward has been claimed!
                      </h1>
                    </div>
                    <div className="mt-10 flex justify-center">
                      <button
                        className={clsx(
                          btnStyles.flashyButton,
                          'flex items-center w-2/3 rounded-70px py-5 md:px-20 px-10 md:px-100px'
                        )}
                        onClick={() => history.push(`/${currentUser.username}/transactions`)}
                      >
                        <span className="font-arial text-lg font-bold leading-21px text-white md:text-3xl md:leading-21px">
                          Check out transactions
                        </span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    {hasEnoughSol ? (
                      <div className="w-1/2">
                        <div className="mb-10">
                          Accepting this {ticker} reward will cost you a one-time fee of ~
                          {MIN_BALANCE_FOR_SPL} SOL (it's the network fee required to open an SPL
                          token account). Click the button below to accept this reward.
                        </div>
                        <button
                          className={clsx(
                            btnStyles.flashyButton,
                            'flex items-center justify-center w-full rounded-70px py-5 md:px-20 px-10 md:px-100px'
                          )}
                          onClick={acceptReward}
                        >
                          {isClaimLoading ? (
                            <Loading width="30" />
                          ) : (
                            <span className="font-arial text-lg font-bold leading-21px text-white md:text-3xl md:leading-21px">
                              Accept ${ticker} reward
                            </span>
                          )}
                        </button>
                      </div>
                    ) : (
                      <div className="w-1/2">
                        <div className="mb-10">
                          In order to accept your {ticker} reward, you must deposit ~
                          {MIN_BALANCE_FOR_SPL} SOL or more into your Hey Wallet address. Please use
                          the address below and reload page when complete:
                        </div>
                        <WalletAddress
                          solanaAddress={currentUser?.walletPublicKey}
                          ethereumAddress={currentUser?.walletPublicKeyEth}
                        />
                      </div>
                    )}
                    <div className="pt-20">
                      <a
                        className="cursor-pointer"
                        onClick={() => history.goBack()}
                        onKeyDown={() => 1}
                        role="button"
                        tabIndex={0}
                        href="/#"
                      >
                        &larr; return to profile
                      </a>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default ClaimRewardPage
