import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { Helmet } from 'react-helmet'
import { useCallback, useEffect, useState } from 'react'

import Loading from '../../../components/Loading'
import SecretBox from '../../../components/SecretBox'

import { ApiURL } from '../../../config'
import { SESSION_COOKIE_NAME } from '../../../constant'

import styles from './Secret.module.css'

const Secret = () => {
  const { push } = useHistory()
  const [cookies] = useCookies([SESSION_COOKIE_NAME])
  const [isLoading, setIsLoading] = useState(true)
  const [keyPair, setKeyPair] = useState(null)

  const getKeypair = useCallback(() => {
    fetch(`${ApiURL}users/wallets/code`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies[SESSION_COOKIE_NAME]}`,
      },
      method: 'POST',
    })
      .then((res) => {
        res.json().then((data) => {
          if (
            data.errors &&
            data.errors.length > 0 &&
            data.errors[0].message === 'Account already activated.'
          ) {
            push('/')
          } else {
            setKeyPair(data)
            setIsLoading(false)
          }
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }, [cookies, push])

  const unlockWallet = () => {
    if (!keyPair) {
      return
    }
    fetch(`${ApiURL}users/unlock`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies[SESSION_COOKIE_NAME]}`,
      },
      method: 'POST',
      body: JSON.stringify({
        authCode: keyPair?.authCode,
      }),
    }).then((res) => {
      if (res.ok) {
        push('/welcome')
      }
    })
  }

  useEffect(() => {
    getKeypair()
  }, [getKeypair])

  return (
    <div
      className={`flex w-full h-full flex-col items-center pt-40 sm:w-700px ${styles.container}`}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Secret Password - Hey Wallet</title>
        <meta
          name="description"
          content="Save these 12 words in a very secure location and never show these to anybody ever again."
        />
      </Helmet>
      {isLoading ? (
        <Loading width="100" screenCentered />
      ) : (
        <div className="fade-focus-in flex flex-col items-center pb-6">
          <h1 className="font-colfax mb-4 text-center text-56 font-black leading-none tracking-tight text-white">
            Below is the password to your new wallet:
          </h1>
          <SecretBox>{keyPair?.mnemonic}</SecretBox>
          <h2 className="mt-4 text-center text-3xl font-light text-gray-c0c0c0">
            Save these 12 words in a very secure location and never show these to anybody ever
            again. If you do, you&#39;ll lose all your money.
          </h2>
          <button className="mt-10 text-3xl font-bold text-white underline" onClick={unlockWallet}>
            Ok, I saved it.
          </button>
        </div>
      )}
    </div>
  )
}

export default Secret
