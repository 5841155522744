import { useEffect, useMemo, useState, useCallback } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { throttle } from 'lodash'
import useInterval from 'react-useinterval'
import { PublicKey } from '@solana/web3.js'
import { MintLayout, TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { TokenListProvider } from '@solana/spl-token-registry'
import { programs } from '@metaplex/js'
import { ethers } from 'ethers'
import { Network, Alchemy } from 'alchemy-sdk'

import UserPageErrorHandler from './UserPageErrorHandler'
import Loading from '../../../components/Loading'
import ErrorDisplay from '../../../components/ErrorDisplay'
import UserHeader from '../../../components/UserHeader'
import WalletAddress from '../../../components/WalletAddress'
import UserMainTabs from '../../../components/UserMainTabs'
import useWindowListener from '../../../hooks/useWindowListener'
import { useGetUserQuery } from '../../../services/userApi'
import { useGetTokensQuery, useGetLatestTokensQuery } from '../../../services/tokenApi'
import { useGetAllPricesQuery, useGetBirdeyePricesQuery } from '../../../services/priceApi'
import { useGetUserWalletTransactionsByPageQuery } from '../../../services/walletApi'
import { useGetUserNftsQuery } from '../../../services/userNftsApi'
import styles from './User.module.css'
import twitterBtnStyles from '../../../components/TweetButton/TweetButton.module.css'
import {
  initialOfflineServices as initOfflineServices,
  ServiceNames,
  ThrottleDelays,
  ethNullAddress,
} from '../../../constant'
import {
  getSOLFromLamport,
  getTokenAmountFromLamport,
  getFixedDollarFromLamport,
  sumNumbers,
  objectKeys,
} from '../../../utils/common'
import { web3Connection } from '../../../utils/web3'
import { MainChainID, alchemyKey, ethRpcUrl } from '../../../config'
import { servicePollingInterval } from './pollingIntervals'

const Metadata = programs.metadata.Metadata
const MetadataData = programs.metadata.MetadataData

const settings = {
  apiKey: alchemyKey,
  network: Network.MATIC_MUMBAI,
}

const alchemy = new Alchemy(settings)

const ethProvider = new ethers.JsonRpcProvider(ethRpcUrl)

const UserPage = () => {
  const { id } = useParams()
  const userID = useMemo(() => (id?.[0] === '@' ? id.slice(1) : id), [id])
  const {
    data: user,
    isError: isUserError,
    isLoading: isUserLoading,
    error: userError,
  } = useGetUserQuery(userID)

  const { data: tokens } = useGetTokensQuery()
  const { data: latestTokens } = useGetLatestTokensQuery()

  const tokenMap = useMemo(() => {
    const assetMap = {}
    if (tokens && tokens.length > 0) {
      tokens.forEach((token) => {
        assetMap[token.address] = token
      })
    }
    return assetMap
  }, [tokens])

  const latestTokenMap = useMemo(() => {
    const assetMap = {}
    if (latestTokens && latestTokens.length > 0) {
      latestTokens.forEach((token) => {
        assetMap[token.address] = token
      })
    }
    return assetMap
  }, [latestTokens])

  const tokenCoingeckoIds = useMemo(
    () => objectKeys(tokenMap).map((mint) => tokenMap[mint].coingeckoId),
    [tokenMap],
  )
  const missingTokenMints = useMemo(
    () => objectKeys(tokenMap).filter((mint) => !tokenMap[mint].coingeckoId),
    [tokenMap],
  )

  const [isActiveTab, setIsActiveTab] = useState(true)
  const [offlineServices, setOfflineServices] = useState({ ...initOfflineServices })
  const [userSolLamport, setUserSolLamport] = useState('0')
  const [tokenMetaMap, setTokenMetaMap] = useState(new Map())
  const [transactionPageLink, setTransactionPageUrl] = useState('')
  const [userBalances, setUserBalances] = useState({})
  const [userBalancesEth, setUserBalancesEth] = useState({})
  const [nftMetadata, setNftMetadata] = useState({})

  const {
    [ServiceNames.solana]: isSolanaOffline,
    [ServiceNames.prices]: isPricesOffline,
    [ServiceNames.heywallet]: isHeyWalletOffline,
  } = offlineServices

  const anyBesidesSolanaOffline = isPricesOffline || isHeyWalletOffline
  const anyBesidesPricesOffline = isSolanaOffline || isHeyWalletOffline
  const anyBesidesHeyWalletOffline = isSolanaOffline || isPricesOffline

  const {
    data: allPricesData,
    error: pricesError,
    refetch: refetchPrices,
  } = useGetAllPricesQuery(tokenCoingeckoIds, {
    pollingInterval: servicePollingInterval(
      isActiveTab,
      'prices',
      isPricesOffline,
      anyBesidesPricesOffline,
    ),
  })

  const { data: birdeyePricesData, refetch: refetchBirdeyePrices } = useGetBirdeyePricesQuery(
    missingTokenMints,
    {
      pollingInterval: servicePollingInterval(
        isActiveTab,
        'prices',
        isPricesOffline,
        anyBesidesPricesOffline,
      ),
    },
  )

  const allPrices = useMemo(
    () => (allPricesData && objectKeys(allPricesData).length > 0 ? allPricesData : {}),
    [allPricesData],
  )

  const birdeyePrices = useMemo(
    () =>
      birdeyePricesData?.success && objectKeys(birdeyePricesData.data).length > 0
        ? birdeyePricesData.data
        : {},
    [birdeyePricesData],
  )

  const {
    data: nfts,
    error: nftsError,
    refetch: refetchNfts,
  } = useGetUserNftsQuery(user?.walletPublicKey || '', { skip: !user?.walletPublicKey })

  const {
    data: transactions,
    isLoading: isTxLoading,
    error: transactionsError,
    refetch: refetchTransactions,
  } = useGetUserWalletTransactionsByPageQuery(
    transactionPageLink || `/wallets/${user?.walletPublicKey}/transactions`,
    { skip: !user?.walletPublicKey },
  )

  const getBalanceFromWeb3 = useCallback(async () => {
    if (user?.walletPublicKey) {
      let moneyBalance = 0
      try {
        moneyBalance = await web3Connection.getBalance(new PublicKey(user?.walletPublicKey))
        setOfflineServices((state) => ({
          ...state,
          [ServiceNames.solana]: false,
        }))
      } catch (e) {
        setOfflineServices((state) => ({
          ...state,
          [ServiceNames.solana]: true,
        }))
      }
      setUserSolLamport(moneyBalance.toString())
    }
  }, [user?.walletPublicKey])

  const getDollarByAsset = useCallback(
    (lamports, asset) => {
      if (allPrices && allPrices[asset] && allPrices[asset].usd && lamports) {
        return getFixedDollarFromLamport(lamports, allPrices[asset].usd)
      } else if (birdeyePrices && birdeyePrices[asset] && birdeyePrices[asset].value && lamports) {
        return getFixedDollarFromLamport(lamports, birdeyePrices[asset].value)
      } else {
        return null
      }
    },
    [allPrices, birdeyePrices],
  )

  const getTokenAccountsByOwner = useCallback(async () => {
    if (user?.walletPublicKey) {
      const userPublicKey = new PublicKey(user?.walletPublicKey)
      const assets = {}

      try {
        const accountsRes = await web3Connection.getParsedTokenAccountsByOwner(userPublicKey, {
          programId: TOKEN_PROGRAM_ID,
        })

        accountsRes.value.forEach((e) => {
          const mintPublicKey = e.account.data.parsed.info.mint
          const { amount, decimals } = e.account.data.parsed.info.tokenAmount
          const formattedAmount = getTokenAmountFromLamport(amount, decimals)
          assets[mintPublicKey] = {
            lamports: amount,
            formatted: formattedAmount,
          }
        })

        setOfflineServices((state) => ({
          ...state,
          [ServiceNames.solana]: false,
        }))
      } catch (e) {
        setOfflineServices((state) => ({
          ...state,
          [ServiceNames.solana]: true,
        }))
      }

      // Keep the last updates while overwriting any new changes
      setUserBalances(assets)
    }
  }, [user?.walletPublicKey])

  const getEthBalances = useCallback(async () => {
    if (user?.walletPublicKeyEth) {
      const balances = await alchemy.core.getTokenBalances(user.walletPublicKeyEth)
      const ethBalance = await ethProvider.getBalance(user.walletPublicKeyEth)

      const assets = {}
      balances.tokenBalances.forEach((e) => {
        assets[e.contractAddress] = e.tokenBalance
      })
      assets[ethNullAddress] = ethBalance
      setUserBalancesEth(assets)
    }
  }, [user?.walletPublicKeyEth])

  const getWalletBalances = useCallback(async () => {
    getTokenAccountsByOwner()
    getEthBalances()
  }, [getTokenAccountsByOwner, getEthBalances])

  /**
   * Throttle re-fetching tokens so that the user can click back and forth between
   * tabs quickly without spamming requests.
   */
  const refreshTokens = useMemo(
    () => throttle(getWalletBalances, ThrottleDelays.tokens * 1000),
    [getWalletBalances],
  )
  /**
   * Throttle re-fetching balance so that the user can click back and forth between
   * tabs quickly without spamming requests.
   */
  const refreshBalance = useMemo(
    () => throttle(getBalanceFromWeb3, ThrottleDelays.balance * 1000),
    [getBalanceFromWeb3],
  )
  /**
   * Throttle re-fetching NFTs so that the user can click back and forth between
   * tabs quickly without spamming requests.
   */
  const refreshNfts = useMemo(
    () => throttle(refetchNfts, ThrottleDelays.nfts * 1000),
    [refetchNfts],
  )
  /**
   * Throttle re-fetching transactions so that the user can click back and forth between
   * tabs quickly without spamming requests.
   */
  const refreshTransactions = useMemo(
    () => throttle(refetchTransactions, ThrottleDelays.transactions * 1000),
    [refetchTransactions],
  )

  const sortedAssets = useMemo(() => {
    const assets = []
    let solAsset
    let ethAsset

    Object.keys(tokenMap).forEach((mint) => {
      const meta = tokenMetaMap.get(mint)
      const token = tokenMap[mint]
      let balance = 0
      let rawBalance = 0

      if (token.chainId === 'SOL') {
        balance = userBalances[mint]?.formatted || 0
        rawBalance = userBalances[mint]?.lamports || 0
      } else {
        const rawBal = userBalancesEth[mint.toLowerCase()]
        if (rawBal) {
          const val = BigInt(rawBal)
          balance = ethers.formatUnits(val, token.decimals)
          rawBalance = rawBal
        }
      }

      const dollarAmount = getDollarByAsset(balance, token.coingeckoId ? token.coingeckoId : mint)

      const newAsset = {
        lamports: balance,
        rawLamports: rawBalance,
        dollar: dollarAmount,
        symbol: token.symbol,
        name: token.name || meta?.name,
        logoURI: token.iconImageUrl || meta?.logoURI,
        decimals: token.decimals,
        chainId: token.chainId,
      }

      if (token.symbol === 'SOL') {
        const solBalance = getSOLFromLamport(userSolLamport)
        newAsset.lamports = solBalance
        newAsset.rawLamports = userSolLamport
        newAsset.decimals = 9
        newAsset.dollar = getDollarByAsset(solBalance, token.coingeckoId ? token.coingeckoId : mint)
        solAsset = newAsset
        solAsset.name = 'Solana'
      } else if (token.chainId === 'ETH' && token.address === ethNullAddress) {
        ethAsset = newAsset
      } else {
        assets.push(newAsset)
      }
    })

    const filteredAssets = assets.filter((asset) => parseFloat(asset.lamports) !== 0)

    const sorted = filteredAssets.sort((a, b) => {
      if (a.dollar === null || b.dollar === null) return 0
      return parseFloat(b.dollar) - parseFloat(a.dollar)
    })

    if (solAsset) {
      sorted.unshift(solAsset)
    }
    if (ethAsset) {
      sorted.unshift(ethAsset)
    }

    Object.keys(latestTokenMap).forEach((mint) => {
      const token = latestTokenMap[mint]
      const meta = tokenMetaMap.get(mint)

      const newAsset = {
        lamports: 0,
        rawLamports: 0,
        dollar: 0,
        symbol: token.symbol,
        name: token.name || meta?.name,
        logoURI: token.iconImageUrl || meta?.logoURI,
        decimals: token.decimals,
        chainId: token.chainId,
      }

      sorted.push(newAsset)
    })

    return sorted
  }, [
    userBalances,
    userBalancesEth,
    tokenMetaMap,
    userSolLamport,
    tokenMap,
    latestTokenMap,
    getDollarByAsset,
  ])

  const userBudgetUSD = useMemo(() => {
    // If the price of Solana is unavailable, display "--"
    if (sortedAssets.find((asset) => asset.symbol === 'SOL')?.dollar === null) return '--'

    // If the price of some token is unavailable, don't count it towards the user's budget
    return sumNumbers(sortedAssets.map((item) => (item.dollar === null ? '0' : item.dollar)))
  }, [sortedAssets])

  useInterval(
    refreshTokens,
    user?.walletPublicKey
      ? servicePollingInterval(isActiveTab, 'tokens', isSolanaOffline, anyBesidesSolanaOffline)
      : null,
  )
  useInterval(
    refreshBalance,
    user?.walletPublicKey
      ? servicePollingInterval(isActiveTab, 'balance', isSolanaOffline, anyBesidesSolanaOffline)
      : null,
  )
  useInterval(
    refreshNfts,
    user?.walletPublicKey
      ? servicePollingInterval(isActiveTab, 'nfts', isHeyWalletOffline, anyBesidesHeyWalletOffline)
      : null,
  )
  useInterval(
    refreshTransactions,
    user?.walletPublicKey
      ? servicePollingInterval(
          isActiveTab,
          'transactions',
          isHeyWalletOffline,
          anyBesidesHeyWalletOffline,
        )
      : null,
  )

  const handleFocus = useCallback(() => setIsActiveTab(true), [])
  const handleBlur = useCallback(() => setIsActiveTab(false), [])
  useWindowListener('focus', handleFocus)
  useWindowListener('blur', handleBlur)

  useEffect(() => {
    refetchPrices()
  }, [refetchPrices, tokenCoingeckoIds])

  useEffect(() => {
    refetchBirdeyePrices()
  }, [refetchBirdeyePrices, missingTokenMints])

  useEffect(() => {
    if (allPrices || birdeyePrices) {
      refreshTokens()
      refreshBalance()
    }
  }, [allPrices, birdeyePrices, refreshTokens, refreshBalance])

  useEffect(() => {
    if (user?.wallet_lamports)
      setUserSolLamport(user?.wallet_lamports?.length ? user.wallet_lamports : '0.00')
  }, [user?.wallet_lamports])

  const getTokenMetadata = useCallback(async (mints) => {
    const pdas = await Promise.all(mints.map(async (mint) => Metadata.getPDA(mint)))
    // we mix those in order to save rpc calls
    const accounts = await web3Connection.getMultipleAccountsInfo([...mints, ...pdas])
    const metadata = new Map()
    const metaUrls = []

    accounts.map((e, i) => {
      if (i >= mints.length && e) {
        const parsed = MetadataData.deserialize(e.data)
        metadata.set(parsed.mint, parsed)
        metaUrls.push(parsed.data.uri)
      }

      return null
    })

    const imageUrls = await Promise.all(
      metaUrls.map(async (url) => {
        const res = await fetch(url)
        try {
          const json = await res.json()
          return json.image
        } catch (e) {
          return ''
        }
      }),
    )

    accounts.map((e, i) => {
      if (i < mints.length && e) {
        const mint = mints[i].toBase58()
        const parsed = MintLayout.decode(e.data)
        const meta = metadata.get(mint)
        meta.decimals = parsed.decimals
        meta.image = imageUrls[i]
        metadata.set(mint, meta)
      }

      return null
    })
    return metadata
  }, [])

  const getEthTokenMetadata = async (addresses) => {
    return await Promise.allSettled(
      addresses.map(async (address) => {
        const res = await alchemy.core.getTokenMetadata(address)
        return {
          address,
          ...res,
        }
      }),
    )
  }

  useEffect(() => {
    new TokenListProvider().resolve().then((remoteTokens) => {
      const tokenList = remoteTokens.filterByChainId(MainChainID).getList()
      const tokenAddresses = objectKeys(tokenMap)

      const tokMap = tokenList.reduce((map, item) => {
        tokenAddresses.includes(item.address) && map.set(item.address, item)
        return map
      }, new Map())

      const missing = []
      const ethAddresses = []
      tokenAddresses.forEach((e) => {
        if (!tokMap.get(e) && tokenMap[e].chainId === 'SOL') {
          missing.push(new PublicKey(e))
        }
        if (tokenMap[e].chainId === 'ETH' && tokenMap[e].address !== ethNullAddress) {
          ethAddresses.push(e)
        }
      })

      if (missing.length > 0) {
        getTokenMetadata(missing).then((res) => {
          res.forEach((el) => {
            tokMap.set(el.mint, {
              address: el.mint,
              chainId: 101,
              decimals: el.decimals,
              logoURI: el.image,
              name: el.data.name,
              symbol: el.data.symbol,
            })
          })

          setTokenMetaMap(tokMap)
        })
      } else {
        setTokenMetaMap(tokMap)
      }

      if (ethAddresses.length > 0) {
        getEthTokenMetadata(ethAddresses).then((res) => {
          res.forEach((e) => {
            const el = e.value
            if (!el) return
            tokMap.set(el.address, {
              address: el.address,
              chainId: 'ETH',
              decimals: el.decimals,
              logoURI: el.logo,
              name: el.name,
              symbol: el.symbol,
            })
          })
        })
      }
    })
  }, [getTokenMetadata, tokenMap])

  useEffect(() => {
    if (nfts?.sol?.length > 0) {
      const mints = nfts.sol.map((nft) => new PublicKey(nft.mintPublicKey))
      const metadata = {}
      getTokenMetadata(mints).then((res) => {
        if (res) {
          for (const [key, value] of res) {
            fetch(value.data.uri).then((res) => {
              res.json().then((json) => {
                metadata[key] = json
                setNftMetadata(metadata)
              })
            })
          }
        }
      })
    }
  }, [nfts, getTokenMetadata])

  return (
    <div className={`mx-5 flex h-full w-full flex-col items-center pt-12 ${styles.container}`}>
      {isUserError ? (
        <ErrorDisplay error={userError} />
      ) : isUserLoading || !user ? (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Hey Wallet!</title>
            <meta name="description" content="Loading Hey Wallet" />
          </Helmet>
          <Loading width="100" screenCentered />
        </>
      ) : (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{user?.name ? user?.name : user?.username} - Hey Wallet</title>
            <meta
              name="description"
              content={`This is the wallet for ${user.name}. You can view all the owner's tokens, NFTs, and transactions.`}
            />
          </Helmet>

          {/* User Header */}
          <UserHeader user={user} userBudgetUSD={userBudgetUSD} />

          {user && !user.claimed && (
            <div>
              <h1 className="font-colfax mt-10 mb-5 flex justify-center text-center text-3xl font-normal leading-none text-white">
                <span className="inline-block px-20 leading-normal">
                  You need to activate your wallet!👇
                </span>
              </h1>
              <Link
                to="/verify/secret"
                className={`flex items-center justify-center rounded-70px py-5 px-20 md:px-100px ${twitterBtnStyles.flashyButton} z-0`}
              >
                <span className="font-arial text-lg font-bold leading-21px text-white md:text-3xl md:leading-21px">
                  Activate Your Wallet
                </span>
              </Link>
            </div>
          )}

          {/* Wallet Address */}
          {user?.walletPublicKey && user?.claimed && (
            <WalletAddress
              solanaAddress={user?.walletPublicKey}
              ethereumAddress={user?.walletPublicKeyEth}
            />
          )}

          <UserMainTabs
            user={user}
            sortedAssets={sortedAssets}
            nfts={nfts}
            nftsMetadata={nftMetadata}
            transactions={transactions}
            refreshTokens={refreshTokens}
            refreshBalance={refreshBalance}
            refreshNfts={refreshNfts}
            refreshTransactions={refreshTransactions}
            setTransactionPageUrl={setTransactionPageUrl}
            transactionsLoading={isTxLoading}
          />

          <UserPageErrorHandler
            offlineServices={offlineServices}
            setOfflineServices={setOfflineServices}
            pricesError={pricesError}
            nftsError={nftsError}
            transactionsError={transactionsError}
          />

          {/* Disclaimer */}
          <p className="fade-focus-in mt-4 px-2 pb-6 text-center text-sm font-normal text-gray-969696">
            This service is still in Beta. Please be careful and do not deposit anything more than
            you can lose. By depositing into this account, you are agreeing to our{' '}
            <Link to="/terms">
              <button className="cursor-pointer font-bold underline">terms of service</button>
            </Link>
            .
          </p>
        </>
      )}
    </div>
  )
}

export default UserPage
