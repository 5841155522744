export const navRoutes = [
  {
    name: 'Wallet',
    path: '/wallet',
  },
  {
    name: 'FAQs',
    path: '/faq',
  },
  {
    name: 'About Us',
    path: '/about',
  },
  {
    name: 'Discord',
    path: 'https://discord.gg/7SnAX5SxTd',
    isOutbound: true,
  },
]

export const whiteLabelNavRoutes = [
  {
    name: 'Signup',
    path: '/verify/secret',
  },
]

export const ServiceNames = {
  solana: 'Solana RPC',
  prices: 'CoinGecko network',
  heywallet: 'Hey Wallet network',
}

/**
 * All services are assumed to be initially online, so all properties are false.
 */
export const initialOfflineServices = Object.fromEntries(
  Object.entries(ServiceNames).map(([, value]) => [value, false]),
)

/**
 * Number of seconds between retrying a service connection when offline.
 */
export const offlineRetryInterval = 5

/**
 * Default number of seconds to show a toast notification
 */
export const defaultToastSeconds = 10

/**
 * Number of seconds between regularly polled requests.
 */
export const AutoRefresh = {
  tokens: 60,
  balance: 60,
  prices: process.env.NODE_ENV === 'development' ? 15 : 3,
  nfts: 60,
  transactions: 60,
}

/**
 * Number of seconds between requests when at least one service is offline. When at least one
 * service is offline, it can be assumed the user has connectivity issues.
 */
export const IdleRefresh = {
  tokens: 90,
  balance: 90,
  prices: 15,
  nfts: 90,
  transactions: 90,
}

/**
 * Number of seconds between throttling API requests. `priceApi` does not need to be throttled
 * because it is only queried in an interval, never manually by the user.
 */
export const ThrottleDelays = {
  tokens: 30, // web3Connection
  balance: 30, // web3Connection
  nfts: 120, // useNftApi
  transactions: 60, // walletApi
}

// 0.002 rent + tx fee
export const MIN_BALANCE_FOR_SPL = '0.003'
export const SESSION_COOKIE_NAME = 'heywallet-session'
export const SESSION_COOKIE_LIFETIME = 12 * 30 * 24 * 60 * 60

export const ethNullAddress = '0x0000000000000000000000000000000000000000'
