import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { birdeyeKey } from '../config'

const tagTypes = ['SinglePrice', 'AllPrices', 'BirdeyePrices']

export const priceApi = createApi({
  reducerPath: 'priceApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.coingecko.com/api/v3/' }),
  endpoints: (build) => ({
    getAllPrices: build.query({
      query: (keys) => `simple/price?ids=${keys}&vs_currencies=usd`,
      providesTags: ['AllPrices'],
    }),
  }),
  tagTypes,
})

export const birdeyePriceApi = createApi({
  reducerPath: 'birdeyePriceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://public-api.birdeye.so/public/',
    prepareHeaders: (headers) => {
      headers.set('X-API-KEY', birdeyeKey)
      return headers
    },
  }),
  endpoints: (build) => ({
    getBirdeyePrices: build.query({
      query: (keys) => `multi_price?list_address=${keys}`,
      providesTags: ['BirdeyePrices'],
    }),
  }),
  tagTypes,
})

export const { useGetAllPricesQuery, useGetSinglePriceQuery } = priceApi
export const { useGetBirdeyePricesQuery } = birdeyePriceApi
