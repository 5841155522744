import { baseApi } from './baseApi'

export const tokenApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getTokens: build.query({
      query: (after) => ({
        method: 'get',
        url: '/tokens',
      }),
    }),
    getLatestTokens: build.query({
      query: (after) => ({
        method: 'get',
        url: '/tokens/latest',
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useGetTokensQuery, useGetLatestTokensQuery } = tokenApi
