import { useState } from 'react'
import { Tab } from '@headlessui/react'

const SolanaAddress = ({ address }) => {
  return (
    <div className="rounded-md border-2 border-green-00ffad bg-black px-5 py-5 text-center font-bold leading-none text-white shadow-mdLg text-xl">
      {address}
    </div>
  )
}

export default function WalletAddress({ solanaAddress, ethereumAddress }) {
  let [categories] = useState({
    Solana: {
      id: 'solana',
      color: 'green',
      // icon:
      prompt: 'Deposit SOL or SPL Tokens into your wallet',
      // address: SolanaAddress,
    },
  })

  return (
    <div className="w-full px-2 py-16 sm:px-0">
      <Tab.Group>
        <Tab.Panels className="mt-2">
          {Object.values(categories).map((wallet, idx) => (
            <Tab.Panel key={idx}>
              <div className="flex flex-col">
                <div className="flex w-full flex-col justify-center md:w-auto">
                  <div className="mb-5 flex flex-col items-center justify-center md:flex-row">
                    <p className="px-12 text-center text-xl text-gray-ececec md:px-0 md:leading-5">
                      {wallet.prompt}
                      <span role="img" aria-label="Finger Down" className="pl-2">
                        👇
                      </span>
                    </p>
                  </div>
                  {wallet.id === 'solana' && <SolanaAddress address={solanaAddress} />}
                </div>
              </div>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
