import { useEffect } from 'react'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import { SESSION_COOKIE_NAME } from '../../../constant'
import { ApiURL } from '../../../config'

const Oauth = () => {
  const location = useLocation()
  const [cookies] = useCookies([SESSION_COOKIE_NAME])
  const queryParams = new URLSearchParams(location.search)
  const code = queryParams.get('code')
  const history = useHistory()

  useEffect(() => {
    fetch(`${ApiURL}oauth/discord/${code}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies[SESSION_COOKIE_NAME]}`,
      },
    }).then(() => {
      history.push(`/`)
    })
  }, [code, history, cookies])

  return null
}

export default Oauth
