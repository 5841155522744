import { createElement, useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { toast } from 'react-toastify'

let pageHasToastedUser = false

const ProfileImage = ({ width, src, alt = 'Avatar' }) => {
  const [imageRefresher, forceRefresh] = useReducer((x) => (x += 1), 0)
  const [offlineIntervalId, setOfflineIntervalId] = useState()

  const handleImageError = useCallback(() => {
    if (!pageHasToastedUser) {
      toast.warn('Failed to load one or more profile images.')
      pageHasToastedUser = true
    }

    if (offlineIntervalId === undefined) {
      setOfflineIntervalId(
        setInterval(() => {
          forceRefresh()
        }, 5000),
      )
    }
  }, [offlineIntervalId, setOfflineIntervalId])

  const handleImageSuccess = useCallback(() => {
    if (offlineIntervalId) {
      clearInterval(offlineIntervalId)
      setOfflineIntervalId(undefined)
    }
  }, [offlineIntervalId, setOfflineIntervalId])

  useEffect(() => {
    return () => {
      if (offlineIntervalId) {
        clearInterval(offlineIntervalId)
      }
    }
  }, [offlineIntervalId])

  useEffect(() => {
    return () => {
      pageHasToastedUser = false // User has navigated (component unmounted), so reset flag
    }
  }, [])

  const profileImage = useMemo(() => {
    const imageElement = createElement('img', {
      width,
      // Append random string to src to trigger re-fetch
      src: `${src}${imageRefresher ? `?${new Date().getTime()}` : ''}` || '',
      alt,
      onError: handleImageError,
      onLoad: handleImageSuccess,
    })
    return imageElement
  }, [width, src, alt, imageRefresher, handleImageError, handleImageSuccess])

  return profileImage
}

export default ProfileImage
