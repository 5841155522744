import { baseApi } from './baseApi'

export const nftsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getNfts: build.query({
      query: (after) => ({
        method: 'get',
        url: `/nfts${after ? '?after=' + after : ''}`,
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useGetNftsQuery } = nftsApi
