import clsx from 'clsx'

const SecretBox = ({ children, className, ...props }) => {
  return (
    <div
      {...props}
      className={clsx(
        'pulse-box w-full bg-red-full py-5 px-20 text-center text-2xl leading-8 text-white',
        className,
      )}
    >
      {children}
    </div>
  )
}

export default SecretBox
