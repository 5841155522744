import { baseApi } from './baseApi'

export const walletApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUserWallet: build.query({
      query: (walletPublicKey) => `/wallets/${walletPublicKey}`,
    }),
    getUserWalletTransactions: build.query({
      query: (walletPublicKey) => `/wallets/${walletPublicKey}/transactions`,
    }),
    getUserWalletTransactionsByPage: build.query({
      query: (paginationQuery) => `${paginationQuery}`,
    }),
  }),
  overrideExisting: true,
})

export const {
  useGetUserWalletQuery,
  useGetUserWalletTransactionsQuery,
  useGetUserWalletTransactionsByPageQuery,
} = walletApi
