import clsx from 'clsx'

import styles from './Loading.module.css'
import loadingIcon from '../../assets/icons/loading.svg'

const Loading = ({ screenCentered = false, ...props }) => {
  return (
    <img
      src={loadingIcon}
      alt="Loading"
      className={clsx(screenCentered && styles['screen-middle'])}
      {...props}
    />
  )
}

export default Loading
