import { useCallback, useEffect, useState } from 'react'

import TransactionItem from '../TransactionItem'
import Loading from '../Loading'
import useRect from '../../hooks/useRect'

const TransactionsList = ({ user, transactions, setTransactionPageUrl, transactionsLoading }) => {
  const [allTransactionResults, setAllTransactionResults] = useState([])

  const [userHasScrolled, setUserHasScrolled] = useState(false)
  const [hasReachedBottom, setHasReachedBottom] = useState(false)
  const [rect, ref] = useRect()

  useEffect(() => {
    setAllTransactionResults((prev) => [...prev, ...(transactions?.results || [])])
  }, [transactions])

  const fetchNext = useCallback(() => {
    if (transactions?.page.hasNextPage) {
      setTransactionPageUrl(
        `/wallets/${user?.walletPublicKey}/transactions?after=${transactions.page.after}`,
      )
    }
  }, [
    setTransactionPageUrl,
    transactions?.page.after,
    transactions?.page.hasNextPage,
    user?.walletPublicKey,
  ])

  useEffect(() => {
    const onScroll = () => {
      if (rect && rect.bottom <= window.innerHeight) {
        !hasReachedBottom && setHasReachedBottom(true)
      } else {
        hasReachedBottom && setHasReachedBottom(false)
      }
    }

    document.addEventListener('scroll', onScroll)
    return () => document.removeEventListener('scroll', onScroll)
  }, [hasReachedBottom, rect])

  useEffect(() => {
    /**
     * Ensure that the user has scrolled before we check if they have scrolled to the
     * bottom. `rect` is not updated until the user has scrolled, so values are
     * incorrect on initial page load.
     */
    if (!userHasScrolled) {
      const handleScroll = () => setUserHasScrolled(true)
      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [userHasScrolled])

  useEffect(() => {
    if (userHasScrolled && hasReachedBottom) fetchNext()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userHasScrolled, hasReachedBottom])

  return (
    <div className="fade-focus-in w-full" ref={ref}>
      <div className="w-full">
        {transactionsLoading ? (
          <div className="flex justify-center mb-4">
            <Loading width="50" />
          </div>
        ) : allTransactionResults.length ? (
          <>
            {allTransactionResults.map((transaction, index) => (
              <TransactionItem key={index} transaction={transaction} user={user} />
            ))}
          </>
        ) : (
          <p className="pt-4 pb-7 text-center text-44 font-black text-gray-4b4b4b">None</p>
        )}
      </div>
    </div>
  )
}

export default TransactionsList
