import { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { useCookies } from 'react-cookie'

import AuthContext from '../../../context/AuthContext'

import { oauthRedirectUri, oauthClientId, ApiURL } from '../../../config'
import { SESSION_COOKIE_NAME } from '../../../constant'

import DiscordLogo from '../../../assets/icons/discord-logo.svg'
import ForwardArrowIcon from '../../../assets/icons/forwardArrow.svg'

const Connect = () => {
  const { currentUser, setCurrentUser } = useContext(AuthContext)
  const [cookies] = useCookies([SESSION_COOKIE_NAME])

  const url = `https://discord.com/api/oauth2/authorize?client_id=${oauthClientId}&redirect_uri=${encodeURIComponent(
    oauthRedirectUri
  )}&response_type=code&scope=identify`

  const refreshUser = async () => {
    fetch(`${ApiURL}users`, {
      headers: { Authorization: `Bearer ${cookies[SESSION_COOKIE_NAME]}` },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          setCurrentUser(res)
        }
      })
  }

  const disconnectDiscord = () => {
    fetch(`${ApiURL}discord/disconnect/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies[SESSION_COOKIE_NAME]}`,
      },
    }).then((res) => refreshUser())
  }

  return (
    <div className="fade-focus-in flex w-full flex-col items-center text-white max-w-sm  mt-40">
      <Helmet>
        <title>Connect - Hey Wallet</title>
        <meta name="description" content="Log in to Hey Wallet" />
      </Helmet>
      <div className="fade-focus-in pb-14">
        <h1 className="font-colfax mb-8 text-4xl font-bold leading-none">
          Connect your social accounts!
        </h1>
        {currentUser && (
          <>
            {currentUser.discordUserId ? (
              <>
                <h2 className="font-colfax mb-8 text-3xl font-bold leading-none">
                  Discord connected!
                </h2>
                <p className="text-gray-c0c0c0 mb-12">You can now use Hey Wallet inside Discord.</p>
                <button
                  onClick={disconnectDiscord}
                  className={`inline-block relative w-full rounded-[44px] px-4 py-4 text-22 font-bold mb-[72px]`}
                  style={{ backgroundColor: '#5865F2' }}
                >
                  <span className="flex items-center justify-center">
                    <img src={DiscordLogo} className="ml-auto w-7 h-6" alt="" />
                    <span className="ml-4 mr-8 mt-1">Disconnect Discord</span>
                    <img
                      src={ForwardArrowIcon}
                      className="ml-auto w-[14px] h-[22px] mr-8"
                      style={{ color: '#0F486F' }}
                      alt=""
                    />
                  </span>
                </button>
              </>
            ) : (
              <>
                <p className="text-gray-c0c0c0 mb-12">
                  After verifying, you will be able to use your Hey Wallet in Discord.
                </p>
                <a
                  href={url}
                  className={`inline-block relative w-full rounded-[44px] px-4 py-4 text-22 font-bold mb-[72px]`}
                  style={{ backgroundColor: '#5865F2' }}
                >
                  <span className="flex items-center justify-center">
                    <img src={DiscordLogo} className="ml-auto w-7 h-6" alt="" />
                    <span className="ml-4 mr-8 mt-1">Connect Discord</span>
                    <img
                      src={ForwardArrowIcon}
                      className="ml-auto w-[14px] h-[22px] mr-8"
                      style={{ color: '#0F486F' }}
                      alt=""
                    />
                  </span>
                </a>
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Connect
