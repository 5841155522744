import { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import clsx from 'clsx'
import AuthContext from '../../context/AuthContext'

import { SESSION_COOKIE_NAME } from '../../constant'

import styles from './NavMenu.module.css'

const NavMenu = (props) => {
  const { routes } = props
  const { currentUser, setCurrentUser } = useContext(AuthContext)
  const [signedIn, setSignedIn] = useState(false)
  const [showNav, setShowNav] = useState(false)

  const [cookies, setCookie, removeCookie] = useCookies([SESSION_COOKIE_NAME])

  const toggleNavigation = () => {
    setShowNav((showNav) => !showNav)
  }

  const logout = () => {
    if (cookies[SESSION_COOKIE_NAME]) {
      setCookie(SESSION_COOKIE_NAME, '', {
        path: '/',
      })
      removeCookie(SESSION_COOKIE_NAME)
    }
    setCurrentUser(null)
    setShowNav((showNav) => !showNav)
    // if we try to navigate to home page immediately it will redirect to the user profile
    // because it didn't have a chance to refresh the state yet
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }

  useEffect(() => {
    if (currentUser) {
      setSignedIn(true)
    } else {
      setSignedIn(false)
    }
  }, [currentUser])

  return (
    <div>
      <button
        className="text-3xl select-none cursor-pointer text-white pt-2"
        onClick={toggleNavigation}
      >
        &#xFE19;
      </button>

      <div
        className={clsx(
          'fixed top-0 left-0 z-10 flex flex-col h-screen w-screen bg-black bg-opacity-95 transition-all duration-200 ease-in-out',
          showNav ? 'z-[100] opacity-100' : 'invisible z-minus-1 opacity-0',
        )}
      >
        <div className="text-right pt-10 pr-10">
          <button
            className={clsx(styles['nav-tgl'], styles.active, 'z-20')}
            onClick={toggleNavigation}
          >
            <span aria-hidden="true"></span>
          </button>
        </div>
        <div className={'flex h-screen w-screen flex-col items-center justify-center'}>
          {showNav && (
            <>
              {routes.map((route, index) => (
                <Link
                  to={route.isOutbound ? { pathname: route.path } : route.path}
                  key={index}
                  className="font-colfax mb-10 text-5xl font-black text-white underline transition-colors duration-300 hover:text-green-00ffad"
                  target={route.isOutbound ? '_blank' : '_self'}
                  onClick={toggleNavigation}
                >
                  {route.name}
                </Link>
              ))}
              {signedIn && (
                <button
                  className="font-colfax mb-10 text-5xl font-black text-white underline transition-colors duration-300 hover:text-green-00ffad"
                  onClick={logout}
                >
                  Logout
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default NavMenu
