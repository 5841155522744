import { baseApi } from './baseApi'

export const nftApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUserNfts: build.query({
      query: (walletPublicKey) => `/wallets/${walletPublicKey}/nfts`,
    }),
  }),
  overrideExisting: false,
})

export const { useGetUserNftsQuery } = nftApi
