import { useState, useEffect, useCallback, useContext } from 'react'
import { useParams } from 'react-router'
import { useCookies } from 'react-cookie'
import { Link } from 'react-router-dom'
import { TwitterTweetEmbed } from 'react-twitter-embed'

import Loading from '../../../components/Loading'

import { ApiURL } from '../../../config'
import { SESSION_COOKIE_NAME } from '../../../constant'
import { formatDate } from '../../../utils/common'
import AuthContext from '../../../context/AuthContext'

import outgoingTXIcon from '../../../assets/icons/outgoingTX.svg'
import XIcon from '../../../assets/icons/x-white.svg'
import ForwardArrowIcon from '../../../assets/icons/forwardArrow.svg'

const RewardPage = () => {
  const { id } = useParams()
  const { currentUser } = useContext(AuthContext)

  const [isLoading, setIsLoading] = useState(true)
  const [reward, setReward] = useState(null)
  // const [refreshInterval, setRefreshInterval] = useState(null)
  const [cookies] = useCookies([SESSION_COOKIE_NAME])

  const fetchReward = useCallback(async () => {
    fetch(`${ApiURL}rewards/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies[SESSION_COOKIE_NAME]}`,
      },
    })
      .then((res) => {
        return res.json()
      })
      .then((res) => {
        setIsLoading(false)
        if (!res.error) {
          setReward(res)
        }
      })
  }, [id, cookies])

  const handleLogin = useCallback(
    async (e) => {
      e.preventDefault()
      const res = await fetch(`${ApiURL}twitter/oauth?redirectTo=/rewards/${id}`, {
        credentials: 'include',
      })
      if (res.status === 200) {
        const { url } = await res.json()
        window.location.href = url
      }
    },
    [id]
  )

  // useEffect(() => {
  //   if (!reward) return
  //   if (refreshInterval) {
  //     if (reward.totalRewarded >= reward.maxRewards) {
  //       clearInterval(refreshInterval)
  //     }
  //   } else {
  //     if (reward.maxRewards > 0 && reward.totalRewarded < reward.maxRewards) {
  //       const interval = setInterval(() => {
  //         fetchReward()
  //       }, 2000)
  //       setRefreshInterval(interval)
  //     }
  //   }
  // }, [reward, fetchReward, refreshInterval])

  useEffect(() => {
    fetchReward()
  }, [fetchReward])

  return (
    <>
      <div className="max-w-[428px] w-full h-full px-4 text-white pt-24 pb-6">
        {isLoading ? (
          <Loading width="100" screenCentered />
        ) : reward ? (
          <>
            {reward.twitterTweetId && (
              <div className="mb-8 overflow-y-hidden">
                <TwitterTweetEmbed
                  tweetId={reward.twitterTweetId}
                  options={{
                    hideCard: true,
                    hideThread: true,
                    theme: 'light',
                  }}
                  placeholder={
                    <div
                      className={`animate-pulse bg-white h-64 max-w-[396px] w-full rounded-3xl`}
                    ></div>
                  }
                />
              </div>
            )}

            <div className="bg-[rgb(35,36,36)] py-4 px-5 mb-8 rounded-3xl">
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <>
                    <img src={outgoingTXIcon} className="mr-[17px] w-6 h-6" alt="" />
                    <span className="text-lg mr-2">
                      {reward.amount} {reward.coin}
                    </span>
                  </>
                </div>
                <div className="text-md text-[rgb(162,163,163)] leading-none">
                  to the first {reward.maxRewards}{' '}
                  {reward.twitterTweetId ? 'retweets' : 'reactions'}
                </div>
              </div>
            </div>

            {currentUser ? (
              <>
                <div className="bg-[rgb(35,36,36)] py-6 px-5 rounded-3xl mb-8 ">
                  <div className="text-white text-2xl font-medium mb-4">
                    {reward.rewardTx
                      ? 'You won a reward!'
                      : reward.twitterTweetId
                      ? 'Retweet to win'
                      : 'React to win'}
                  </div>
                  {reward.rewardTx ? (
                    <>
                      <div className="text-[rgb(162,163,163)]">
                        <Link to={`/${reward.author.username}`}>@{reward.author.username}</Link>{' '}
                        sent you a reward to your Twitter wallet. You can access your reward by
                        {!currentUser.claimed && ' verifying your account and'} claiming it.
                      </div>
                      <br />
                      {currentUser.claimed ? (
                        <Link
                          to={`/transactions/${reward.rewardTx}`}
                          className="inline-block bg-[rgb(69,70,71)] text-center pt-[18px] pb-[17px] font-medium text-2xl leading-7 rounded-[36px] w-full"
                        >
                          Check the reward
                        </Link>
                      ) : (
                        <>
                          <div className="text-[rgb(162,163,163)] mb-6">
                            To get started, verify your account and activate your Twitter wallet.
                          </div>
                          <Link
                            to={reward.twitterTweetId ? '/verify/secret' : '/connect'}
                            className="inline-block bg-[rgb(69,70,71)] text-center pt-[18px] pb-[17px] font-medium text-2xl leading-7 rounded-[36px] w-full"
                          >
                            Activate your Wallet
                          </Link>
                        </>
                      )}
                    </>
                  ) : (
                    <div className="text-[rgb(162,163,163)]">
                      {reward.twitterTweetId ? (
                        <span>
                          Retweet this{' '}
                          <a
                            href={`https://twitter.com/${reward.author.username}/status/${reward.twitterTweetId}`}
                          >
                            tweet
                          </a>
                        </span>
                      ) : (
                        'React to this Discord message'
                      )}{' '}
                      to get a reward.
                      {reward.twitterTweetId && (
                        <a
                          className={`relative mb-12 inline-block w-full rounded-[44px] px-4 py-4 text-sm sm:text-lg font-bold mt-5`}
                          style={{ backgroundColor: '#1c9bf0' }}
                          target="_blank"
                          rel="noreferrer"
                          href={`https://twitter.com/intent/retweet?tweet_id=${reward.twitterTweetId}`}
                        >
                          <span className="flex items-center justify-center">
                            <img src={XIcon} className="ml-auto h-6 w-7" alt="" />
                            <span className="ml-4 mr-8 mt-1 text-white">Retweet this tweet</span>
                            <img
                              src={ForwardArrowIcon}
                              className="ml-auto lg:mr-8 h-[22px] w-[14px]"
                              style={{ color: '#0F486F' }}
                              alt=""
                            />
                          </span>
                        </a>
                      )}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="bg-[rgb(35,36,36)] py-6 px-5 rounded-3xl mb-8 ">
                <div className="text-white text-2xl font-medium mb-4">
                  Sign in to check your reward
                </div>
                <button
                  className={`relative mb-5 inline-block w-full rounded-[44px] px-4 py-4 text-sm sm:text-lg font-bold mt-5`}
                  style={{ backgroundColor: '#1c9bf0' }}
                  target="_blank"
                  rel="noreferrer"
                  onClick={handleLogin}
                >
                  <span className="flex items-center justify-center">
                    <img src={XIcon} className="ml-auto h-6 w-7" alt="" />
                    <span className="ml-4 mr-8 mt-1 text-white">Sign in with Twitter/X</span>
                    <img
                      src={ForwardArrowIcon}
                      className="ml-auto lg:mr-8 h-[22px] w-[14px]"
                      style={{ color: '#0F486F' }}
                      alt=""
                    />
                  </span>
                </button>
              </div>
            )}

            <div className="bg-[rgb(35,36,36)] py-6 px-5 rounded-3xl mb-8">
              {reward.author && (
                <>
                  <div className="mb-4">Campaign Author</div>
                  <div className="mb-6 text-[rgb(162,163,163)]">
                    <Link to={`/${reward.author?.username}`} className="flex items-center">
                      <span className="max-w-[32px] max-h-[32px] mr-2">
                        <img
                          src={reward.author.profileImageUrl}
                          className="w-full h-full rounded-lg"
                          alt=""
                        />
                      </span>
                      {reward.author?.username}
                    </Link>
                  </div>
                </>
              )}
              <div className="mb-4">Campaign Launched</div>
              <div className="mb-6 text-[rgb(162,163,163)]">
                {formatDate(new Date(reward.createdAt))}
              </div>
              {reward.expiresAt && (
                <>
                  <div className="mb-4">Campaign Expires</div>
                  <div className="mb-6 text-[rgb(162,163,163)]">
                    {formatDate(new Date(reward.expiresAt))}
                  </div>
                </>
              )}
              <div className="mb-4">Total rewards</div>
              <div className="text-[rgb(162,163,163)]">{reward.maxRewards}</div>
            </div>
          </>
        ) : (
          <>
            <div className="text-2xl mb-4 text-center">Reward not found</div>
          </>
        )}
      </div>
    </>
  )
}

export default RewardPage
