import UserPage from '../pages/main/User'
import ClaimRewardPage from '../pages/main/ClaimReward'
import TransactionPage from '../pages/main/Transaction'
import NFTPage from '../pages/main/NFT'
import NftsPage from '../pages/main/Nfts'
import NftDetails from '../pages/main/NftDetails'
import RewardPage from '../pages/main/Reward'
import CompressedNftDetails from '../pages/main/CompressedNftDetails'
import CollectionPage from '../pages/main/Collection'

const mainLayoutRoutes = [
  {
    path: '/rewards/:id/',
    Component: RewardPage,
  },
  {
    path: '/nfts/collection/:mint',
    Component: CollectionPage,
  },
  {
    path: '/nfts/zip/:mint',
    Component: CompressedNftDetails,
  },
  {
    path: '/nfts/details/:mint',
    Component: NftDetails,
  },
  {
    path: '/nfts/:id',
    Component: NFTPage,
  },
  {
    path: '/nfts',
    Component: NftsPage,
  },
  {
    path: '/:id',
    Component: UserPage,
  },
  {
    path: '/:id/NFTs',
    Component: UserPage,
  },
  {
    path: '/:id/transactions',
    Component: UserPage,
  },
  {
    path: '/users/:id/rewards/accept/:ticker',
    Component: ClaimRewardPage,
    authenticated: true,
  },
  {
    path: '/users/:id',
    redirect: '/:id',
  },
  {
    path: '/transactions/:id',
    Component: TransactionPage,
  },
]

export default mainLayoutRoutes
