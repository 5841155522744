import BigNumber from 'bignumber.js'

export const getSOLFromLamport = (lamport) => {
  return new BigNumber(lamport).times('0.000000001').toFixed() // (1) => 0.000000001
}

export const getTokenAmountFromLamport = (lamport, decimals, round) => {
  const multiply = Math.pow(10, -(decimals ?? 0)).toString()
  return new BigNumber(lamport).times(multiply).toFixed(round ? round : 2)
}

export const getUSDFromLamport = (lamport, solToUSD) => {
  return new BigNumber(lamport).times('0.000000001').times(solToUSD).toFixed(2)
}

export const getFixedDollarFromLamport = (lamport, price) => {
  return new BigNumber(lamport).times(price).toFixed(2)
}

export const justifyNumberString = (num) => {
  const bigNum = new BigNumber(num)
  const one = new BigNumber(1)
  switch (bigNum.comparedTo(one)) {
    case null:
      return '0'
    case 1:
      return bigNum.toFixed(2)
    default:
      return bigNum.toFixed()
  }
}

export const sumNumbers = (nums) => {
  let sum = new BigNumber(0)
  for (const num of nums) {
    sum = sum.plus(new BigNumber(num))
  }
  return sum.toFixed()
}

export const formatNumber = (num, digits) => {
  return digits ? new BigNumber(num).toFormat(digits) : new BigNumber(num).toFormat()
}

export const formatDollarAmount = (num) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num)
}

export const formatDate = (date) => {
  return new Intl.DateTimeFormat('en-US', {
    dateStyle: 'medium',
    timeStyle: 'short',
  }).format(date)
}

/**
 * Use this instead of `Object.keys` to get typed keys instead of just 'string'.
 * @param obj Any object
 * @returns Keys of `obj`
 */
export const objectKeys = (obj) => {
  return Object.keys(obj)
}

export const amountToLamports = (amount, decimals) => {
  const dividedBy = new BigNumber(10).pow(decimals * -1)
  return new BigNumber(amount).dividedBy(dividedBy)
}

export const tweetIntent = (text) => {
  return `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`
}

export const dmIntent = (text, recipient, welcomeId) => {
  return `https://twitter.com/messages/compose?recipient_id=${recipient}&text=${encodeURIComponent(
    text,
  )}${welcomeId ? '&welcome_message_id=' + welcomeId : ''}`
}

export const shortenAddress = (addr) => {
  return addr.substring(0, 4) + '...' + addr.substring(addr.length - 3)
}

export const isHwHost = () => {
  const host = window.location.hostname
  return ['beta.heywallet.com', 'heywallet.com', 'localhost', 'social.heywallet.com'].includes(host)
}

export const bidPath = (path) => {
  return (isHwHost() ? '/bidding' : '') + path
}
