import { useState, useEffect, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import { Helmet } from 'react-helmet'

import Loading from '../../../components/Loading'

import { shortenAddress } from '../../../utils/common'
import { ApiURL } from '../../../config'

import twitterGrayIcon from '../../../assets/icons/twitterGray.svg'
import backWhiteArrow from '../../../assets/icons/backWhiteArrow.svg'

const CompressedNftDetails = () => {
  const history = useHistory()
  const { mint } = useParams()

  const [isPageLoading, setIsPageLoading] = useState(true)
  const [error, setError] = useState(null)
  const [nft, setNft] = useState(null)

  const solScan = (account, kind = 'account') => `https://solscan.io/${kind}/${account}`

  const fetchNft = useCallback(async () => {
    fetch(`${ApiURL}compressed-nfts/${mint}`)
      .then((res) => {
        if (res.status === 404) {
          setError("NFT with this mint wasn't found")
        } else {
          return res.json()
        }
      })
      .then((res) => {
        if (res) {
          setNft(res)
          setIsPageLoading(false)
        }
      })
  }, [mint])

  const goBack = () => {
    return history.goBack(-1)
  }

  useEffect(() => {
    fetchNft()
  }, [fetchNft])

  return (
    <div className={`mx-auto w-full max-w-[414px] text-white`}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Loading Hey Wallet" />
        <title>Hey Wallet!</title>
      </Helmet>
      {isPageLoading ? (
        <>
          <Loading width="100" screenCentered />
        </>
      ) : (
        <>
          {error ? (
            <div className="flex-col items-start px-4 pt-12 pb-2 text-base font-medium lg:items-center">
              <button onClick={goBack}>
                <div className="mb-1 flex items-center">
                  <img className="mx-2 mb-1 h-3 w-4" src={backWhiteArrow} alt="" />
                  <div className="text-2xl font-medium">Back</div>
                </div>
              </button>
              <div className="pt-10">{error}</div>
            </div>
          ) : (
            <>
              {nft ? (
                <>
                  <div className="flex-col items-start px-4 pt-12 pb-2 text-base font-medium lg:items-center">
                    <button onClick={goBack}>
                      <div className="mb-1 flex items-center">
                        <img className="mx-2 mb-1 h-3 w-4" src={backWhiteArrow} alt="" />
                        <div className="text-2xl font-medium">Back</div>
                      </div>
                    </button>
                    <div className="my-8 aspect-square max-h-[396px]">
                      <img className="rounded-[24px]" src={nft.imageUrl} alt="" />
                    </div>
                    <h1 className="mx-2 text-2xl font-medium">{nft.name}</h1>

                    <div className="mb-5 mt-10 max-w-[396px]">
                      <div className="mb-8 rounded-[24px] bg-[rgb(35,36,36)] px-4 py-5 font-normal">
                        <div className="mb-4 font-normal text-zinc-400">Owner</div>
                        <div className="flex items-center justify-between">
                          {nft.owner.username ? (
                            <>
                              <Link to={`/@${nft.owner.username}`}>
                                <div className="flex items-center">
                                  <img
                                    className="mr-3 h-10 w-10 rounded-full"
                                    src={nft.owner.imageUrl}
                                    alt=""
                                  />
                                  <div className="mr-3 flex-col">
                                    <div className="break-all">{nft.owner.name}</div>
                                    <div className="text-sm text-zinc-400">
                                      @{nft.owner.username}
                                    </div>
                                  </div>
                                </div>
                              </Link>
                              <a
                                href={`https://twitter.com/${nft.owner.username}`}
                                target="_blank"
                                rel="noreferrer"
                                className="min-w-max rounded-full bg-zinc-600 p-3"
                              >
                                <img className="h-4 w-4" src={twitterGrayIcon} alt="" />
                              </a>
                            </>
                          ) : (
                            <div>
                              <a
                                href={solScan(nft.owner.publicKey)}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {shortenAddress(nft.owner.publicKey)}
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="mb-[23px] max-w-[396px] rounded-3xl bg-[rgb(35,36,36)] px-4 py-5">
                      <div className="mb-4 font-normal text-zinc-400">NFT Details</div>
                      <div className="flex-col">
                        <div className="mb-4 flex items-center justify-between">
                          <div>Mint Address</div>
                          <div className="text-sm text-zinc-400">{shortenAddress(nft.mint)}</div>
                        </div>
                        <div className="mb-4 flex items-center justify-between">
                          <div>Collection Address</div>
                          <div className="text-sm text-zinc-400">
                            {shortenAddress(nft.treeRoot)}
                          </div>
                        </div>
                        <div className="flex items-center justify-between">
                          <div>Owner</div>
                          <div className="text-sm text-zinc-400">
                            <a href={solScan(nft.owner.publicKey)} target="_blank" rel="noreferrer">
                              {shortenAddress(nft.owner.publicKey)}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-[23px] max-w-[396px] rounded-3xl bg-[rgb(35,36,36)] px-4 py-5">
                      <div className="mb-4 font-normal text-zinc-400">Fees & Rebates</div>
                      <div className="flex-col">
                        <div className="flex items-center justify-between">
                          <div>Royalties</div>
                          <div className="text-sm text-zinc-400">
                            {nft.royalties ? nft.royalties / 100 : 0}%
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-10 mb-7 text-center font-[17px] text-[rgb(107,107,107)]">
                      Powered by{' '}
                      <a href="https://heywallet.com" className="font-bold">
                        Hey Wallet
                      </a>
                    </div>
                  </div>
                </>
              ) : null}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default CompressedNftDetails
