import { Link } from 'react-router-dom'
import Helmet from 'react-helmet'

const Page404 = () => {
  return (
    <div className="mt-40 flex flex-col">
      <Helmet title="404 Page not found" />
      <h1 className="text-2xl font-bold text-white">404</h1>
      <h2 className="text-2xl font-bold text-white">Page not found.</h2>
      <h2 className="text-2xl font-bold text-white">
        The page you are looking for might have been removed.
      </h2>

      <Link className="mt-8 text-white" to="/">
        Return to website
      </Link>
    </div>
  )
}

export default Page404
