import { useEffect } from 'react'
import { ServiceNames } from '../../../constant'
import toggleOfflineToasts, { hasDisconnected } from '../../../utils/toggleOfflineToasts'

/**
 * Handles setting `offlineServices` when there are error reponses from the `prices`, `nfts`, or `transactions`
 * services. Errors from the `solana` service are handled in the `getBalanceFromWeb3` and
 * `getTokenAccountsByOwner` functions in UserPage.
 */
const UserPageErrorHandler = ({
  offlineServices,
  setOfflineServices,
  pricesError,
  nftsError,
  transactionsError,
}) => {
  const {
    [ServiceNames.solana]: isSolanaOffline,
    [ServiceNames.prices]: isPricesOffline,
    [ServiceNames.heywallet]: isHeyWalletOffline,
  } = offlineServices

  useEffect(() => {
    if (pricesError) {
      setOfflineServices((state) => ({
        ...state,
        [ServiceNames.prices]: true,
      }))
    } else if (hasDisconnected[ServiceNames.prices]) {
      setOfflineServices((state) => ({
        ...state,
        [ServiceNames.prices]: false,
      }))
    }
  }, [pricesError, setOfflineServices])

  useEffect(() => {
    if (nftsError) {
      setOfflineServices((state) => ({
        ...state,
        [ServiceNames.heywallet]: true,
      }))
    } else if (hasDisconnected[ServiceNames.heywallet]) {
      setOfflineServices((state) => ({
        ...state,
        [ServiceNames.heywallet]: false,
      }))
    }
  }, [nftsError, setOfflineServices])

  useEffect(() => {
    if (transactionsError) {
      setOfflineServices((state) => ({
        ...state,
        [ServiceNames.heywallet]: true,
      }))
    } else if (hasDisconnected[ServiceNames.heywallet]) {
      setOfflineServices((state) => ({
        ...state,
        [ServiceNames.heywallet]: false,
      }))
    }
  }, [transactionsError, setOfflineServices])

  useEffect(() => {
    toggleOfflineToasts(isSolanaOffline, ServiceNames.solana, setOfflineServices)
  }, [isSolanaOffline, setOfflineServices])

  useEffect(() => {
    toggleOfflineToasts(isPricesOffline, ServiceNames.prices, setOfflineServices)
  }, [isPricesOffline, setOfflineServices])

  useEffect(() => {
    toggleOfflineToasts(isHeyWalletOffline, ServiceNames.heywallet, setOfflineServices)
  }, [isHeyWalletOffline, setOfflineServices])

  return null
}

export default UserPageErrorHandler
