import { useEffect, useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router'
import { useCookies } from 'react-cookie'
import { ApiURL } from '../../../config'

import { SESSION_COOKIE_NAME, SESSION_COOKIE_LIFETIME } from '../../../constant'
import Loading from '../../../components/Loading'

import XIcon from '../../../assets/icons/x-white.svg'
import ForwardArrowIcon from '../../../assets/icons/forwardArrow.svg'

const Login = () => {
  const history = useHistory()
  const { search } = useLocation()
  const [, setCookie] = useCookies([SESSION_COOKIE_NAME])
  const qs = new URLSearchParams(search)
  const token = qs.get('oauth_token')
  const verifier = qs.get('oauth_verifier')
  const redirect = qs.get('prevPath')
  const [isLoading, setIsLoading] = useState(false)

  const handleLogin = useCallback(
    async (e) => {
      e.preventDefault()
      setIsLoading(true)
      const res = await fetch(
        `${ApiURL}twitter/oauth${redirect ? `?redirectTo=${redirect}` : ''}`,
        {
          credentials: 'include',
        }
      )
      if (res.status === 200) {
        const { url } = await res.json()
        window.location.href = url
      }
    },
    [redirect]
  )

  useEffect(() => {
    if (token && verifier) {
      setIsLoading(true)
      fetch(`${ApiURL}twitter/callback?oauthToken=${token}&oauthVerifier=${verifier}`, {
        credentials: 'include',
      }).then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setCookie(SESSION_COOKIE_NAME, data.sessionCode, {
              path: '/',
              maxAge: SESSION_COOKIE_LIFETIME,
            })
            if (data.redirectTo) {
              history.push(data.redirectTo)
            } else {
              history.push(`/login`)
            }
          })
        } else {
          console.log('error', res)
        }
      })
    }
  }, [token, verifier, history, setCookie])

  return (
    <div className="fade-focus-in mb-14 flex max-h-full w-full flex-col items-center justify-center px-5 pt-32 pb-5 sm:w-700px sm:px-0">
      <Helmet>
        <title>Login - Hey Wallet</title>
        <meta
          name="description"
          content="Log into your Hey Wallet by entering your Twitter handle."
        />
      </Helmet>
      {isLoading ? (
        <Loading width="100" screenCentered />
      ) : (
        <form className="fade-focus-in mb-14">
          <h1 className="font-colfax text-center text-56 font-black leading-none text-white">
            Login
          </h1>
          {redirect === '/connect' && (
            <p className="text-lg text-white text-center mt-14">
              You're almost there! Logging in with Twitter is required to connect your account and
              get started.
            </p>
          )}
          <div className="text-center px-5">
            <button
              className={`relative mb-[72px] inline-block w-full sm:w-[400px] rounded-[44px] px-4 py-4 text-sm sm:text-lg font-bold mt-20`}
              style={{ backgroundColor: '#1c9bf0' }}
              target="_blank"
              rel="noreferrer"
              onClick={handleLogin}
            >
              <span className="flex items-center justify-center">
                <img src={XIcon} className="ml-auto h-6 w-7" alt="" />
                <span className="ml-4 mr-8 mt-1 text-white">Sign in with Twitter/X</span>
                <img
                  src={ForwardArrowIcon}
                  className="ml-auto lg:mr-8 h-[22px] w-[14px]"
                  style={{ color: '#0F486F' }}
                  alt=""
                />
              </span>
            </button>
          </div>
        </form>
      )}
    </div>
  )
}

export default Login
