import { createElement, useMemo, useState, useEffect } from 'react'
import Loading from '../Loading'
import { getSOLFromLamport } from '../../utils/common'
import solanaIcon from '../../assets/icons/solanaIcon.svg'
import downloadIcon from '../../assets/icons/download.svg'
import verifiedIcon from '../../assets/icons/verified.svg'

import { ApiURL } from '../../config'

const NftCard = ({ nft, offer }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [metadata, setMetadata] = useState(null)

  useEffect(() => {
    fetch(`${ApiURL}nfts/metadata/${nft.mintPublicKey}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Could not retrieve metadata for ${nft.mintPublicKey}`)
        }

        return response.json()
      })
      .then((metadata) => {
        if (metadata?.floorPrice) {
          metadata.floorDiff = Math.round(
            ((metadata.floorPrice - nft.price) / metadata.floorPrice) * 100,
          )
        }

        setMetadata(metadata)
      })
      .catch((error) => {
        console.log(error)
        setMetadata(null)
      })
  }, [nft.mintPublicKey, nft.price])

  const image = useMemo(() => {
    const imageElement = createElement('img', {
      src: metadata?.imageUrl,
      alt: isLoaded ? metadata?.name : '', // Don't show the alt text while the image is loading
      className: 'w-full h-auto rounded-[20px]',
      style: {
        width: '100%',
        height: '100%',
      },
      onLoad: () => setIsLoaded(true),
    })
    return imageElement
  }, [metadata, isLoaded, setIsLoaded])

  return (
    <>
      <div className="relative p-2 bg-zinc-800 rounded-[24px]">
        <div className="max-h-[200px] max-w-[200px] aspect-square mb-2 rounded-[28px]">
          {isLoaded ? (
            image
          ) : (
            <>
              <Loading
                className="absolute self-center w-14"
                style={{
                  left: 'calc(50% - 28px)',
                  top: 'calc(50% - 70px)',
                }}
              />
              <div className="hidden">{image}</div>
            </>
          )}
        </div>
        {isLoaded ? (
          <div className="px-2">
            <div className="truncate text-white">{metadata?.name}</div>
            <div
              className="text-zinc-400 flex items-baseline max-w-[180px] justify-start mt-1"
              style={metadata?.verified ? { color: 'rgb(0, 255, 173)' } : {}}
            >
              {metadata?.verified ? (
                <>
                  <div className="max-w-lg truncate">{metadata.projectDisplayName}</div>
                  <img className="w-4 h-4 self-center mb-[5px] ml-1.5" src={verifiedIcon} alt="" />
                </>
              ) : (
                <div className="font-bold self-top rounded text-xs px-1.5 pt-0.5 bg-zinc-900 mb-1">
                  Unverified
                </div>
              )}
            </div>
            {nft.price ||
              (offer && offer.amount && (
                <div className="flex flex-wrap font-bold mt-1">
                  <span className="flex items-center leading-none">
                    <img className="w-4 h-4" src={solanaIcon} alt="" />
                    <span className="ml-1 mr-2 break-all text-white mt-1">
                      {nft.price || (offer && getSOLFromLamport(offer.amount))}
                    </span>
                  </span>
                  {metadata?.floorDiff && metadata.floorDiff > 0 ? (
                    <span className="flex items-center" style={{ color: 'rgb(255, 0, 229)' }}>
                      <img className="w-4 h-4 mb-1" src={downloadIcon} alt="" />
                      <span className="mx-1 min-w-max">{metadata.floorDiff}%</span>
                    </span>
                  ) : null}
                </div>
              ))}
          </div>
        ) : (
          <div className="animate-pulse h-24 rounded-[20px] bg-zinc-700"></div>
        )}
      </div>
    </>
  )
}

export default NftCard
