import { baseApi } from './baseApi'

export const socialCardApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSocialCard: build.query({
      query: ({ mint, price }) => ({
        method: 'get',
        url: `/nfts/${mint}/${price}/twitter_card`,
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useGetSocialCardQuery } = socialCardApi
