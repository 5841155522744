import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useCookies } from 'react-cookie'
import { ApiURL } from '../../../../config'
import { SESSION_COOKIE_NAME } from '../../../../constant'

const TwitterComplete = () => {
  const [cookies] = useCookies([SESSION_COOKIE_NAME, 'telegram-connect'])
  const [result, setResult] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    const connectTwitter = async () => {
      const telegram = cookies['telegram-connect']

      if (!telegram) {
        setError('Telegram connect not found.')
        return
      }

      try {
        const response = await fetch(`${ApiURL}twitter/connect`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookies[SESSION_COOKIE_NAME]}`,
          },
          body: JSON.stringify({ telegram }),
        })

        if (response.ok) {
          setResult('Twitter/X connected successfully!')
        } else {
          setError('Failed to connect Twitter/X!')
        }
      } catch (error) {
        console.error('Error connecting Twitter:', error)
        setError('An error occurred while connecting Twitter.')
      }
    }

    connectTwitter()
  }, [cookies])

  return (
    <div className="fade-focus-in mb-14 flex max-h-full w-full flex-col items-center justify-center px-5 pt-32 pb-5 sm:w-700px sm:px-0">
      <Helmet>
        <title>Connect your Twitter/X</title>
        <meta
          name="description"
          content="Log into your Hey Wallet by entering your Twitter handle."
        />
      </Helmet>
      <div className="fade-focus-in mb-14 text-center">
        <h1 className="font-colfax text-center text-56 font-black leading-none text-white">
          Connect your Twitter/X
        </h1>
        <p className="text-lg text-white mt-14">
          {error ? (
            <>
              <div className="text-red-500">{error}</div>
              <div className="text-white mt-6">Close this window and try connecting again.</div>
            </>
          ) : result ? (
            <>
              <span className="text-green-500">{result}</span>
              <div className="text-white mt-6">You may close this window.</div>
            </>
          ) : (
            'Connecting your Twitter/X account...'
          )}
        </p>
      </div>
    </div>
  )
}

export default TwitterComplete
