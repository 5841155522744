import { useEffect } from 'react'

const useWindowListener = (event, set, useCapture) => {
  useEffect(() => {
    set()
    window.addEventListener(event, set, useCapture)
    return () => window.removeEventListener(event, set, useCapture)
  }, [event, set, useCapture])
}

export default useWindowListener
