import Faqs from '../pages/general/Faqs'
import About from '../pages/general/About'

const generalLayoutRoutes = [
  {
    path: '/faq',
    Component: Faqs,
  },
  {
    path: '/about',
    Component: About,
  },
  {
    path: '/help',
    redirect: '/faq',
  },
  {
    path: '/terms',
    redirectExternal:
      'https://heywallet.notion.site/Terms-of-Service-d3eebbde97ac4df39c2d5ba52798bbd1?pvs=4',
  },
]

export default generalLayoutRoutes
