import clsx from 'clsx'

import styles from './TweetButton.module.css'

const TweetButton = (props) => {
  return (
    <a
      href={props.to || ''}
      className={clsx(
        styles.flashyButton,
        'flex items-center justify-center rounded-70px py-5 md:px-20 px-10 md:px-100px',
      )}
    >
      <span className="font-arial text-lg font-bold leading-21px text-white md:text-3xl md:leading-21px">
        {props.text || 'Tweet my code'}
      </span>
    </a>
  )
}

export default TweetButton
