import { baseApi } from './baseApi'

export const listingApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getListing: build.query({
      query: (mint) => ({
        method: 'get',
        url: `/nfts/${mint}`,
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useGetListingQuery } = listingApi
