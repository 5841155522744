import { useState, useEffect, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { programs } from '@metaplex/js'
import { PublicKey } from '@solana/web3.js'
import clsx from 'clsx'

import { formatNumber, formatDollarAmount } from '../../utils/common'
import { web3Connection } from '../../utils/web3'
import ProfileImage from '../ProfileImage'
import DiscordIcon from '../../assets/icons/discord-mark-white.svg'

const errorMap = {
  NETWORK_ERROR: 'Network error',
  TOO_LOW_AMOUNT: 'Amount has to be > 0.0009 SOL',
  INSUFFICIENT_FUNDS: 'Not enough funds',
  INSUFFICIENT_FUNDS_TX_FEE: 'Not enough SOL to pay for tx fee',
}

const Metadata = programs.metadata.Metadata
const MetadataData = programs.metadata.MetadataData

const isBase58 = (string) => {
  try {
    const pubkey = new PublicKey(string)
    return PublicKey.isOnCurve(pubkey)
  } catch (e) {
    return false
  }
}

const TransactionItem = ({ transaction, user }) => {
  const history = useHistory()
  const [nftMetadata, setNftMetadata] = useState({})
  const isNft = isBase58(transaction.token)

  const fetchTokenMetadata = useCallback(async (mint) => {
    const pda = await Metadata.getPDA(mint)
    // we mix those in order to save rpc calls
    const account = await web3Connection.getAccountInfo(pda)
    const metadata = MetadataData.deserialize(account.data)
    const offchainMeta = await fetch(metadata.data.uri)
    if (offchainMeta.status === 200) {
      const offchainMetaJson = await offchainMeta.json()
      setNftMetadata(offchainMetaJson)
    }
  }, [])

  useEffect(() => {
    if (isNft) {
      fetchTokenMetadata(transaction.token)
    }
  }, [isNft, transaction, fetchTokenMetadata])

  const hasMetadata = Object.keys(nftMetadata).length > 0

  const tokenName = isNft && hasMetadata ? nftMetadata.name : transaction.token

  const placeholderAvatar = 'https://profile-images.heywallet.com/1402200555561259022'

  return (
    <Link
      className="mb-4 flex w-full items-center justify-between"
      to={`/transactions/${transaction.id}`}
    >
      <div className="flex">
        <div className="mr-3 h-14 w-14 overflow-hidden rounded-full md:h-75px md:w-75px">
          {transaction.action === 'sent' &&
          transaction.source === 'DISCORD' &&
          transaction.user.profileImageUrl === placeholderAvatar ? (
            <div className="flex items-center justify-center h-full w-full p-3 bg-[#5865F2]">
              <img src={DiscordIcon} alt="discord" />
            </div>
          ) : (
            <ProfileImage src={transaction.user.profileImageUrl} />
          )}
        </div>
        <div className="flex flex-col justify-center">
          {/* Type SubSection */}
          <h2 className="font-colfax text-lg font-bold text-gray-50 md:text-2xl flex items-center">
            {transaction.status === 'error' ? (
              <span className="leading-none align-bottom">
                Failed
                {transaction.errorCode === 'INSUFFICIENT_FUNDS' ? (
                  <> [Insufficient {transaction.token}]</>
                ) : (
                  <> [{errorMap[transaction.errorCode] || 'unknown error'}]</>
                )}
              </span>
            ) : (
              <>
                {transaction.status === 'expired' ? (
                  <span className="leading-none align-bottom">Airdrop Expired</span>
                ) : (
                  <>
                    <span className="leading-none align-bottom">
                      {transaction.action === 'sent'
                        ? transaction.status === 'pending_ata'
                          ? 'Waiting to claim'
                          : 'Sent'
                        : 'Received'}
                      {['pending', 'processing'].indexOf(transaction.status) > -1
                        ? ' (Pending)'
                        : ''}
                    </span>
                    {transaction.status === 'pending_ata' &&
                      transaction.action !== 'sent' &&
                      user.claimed && (
                        <span
                          onKeyDown={() => 1}
                          role="button"
                          tabIndex={0}
                          onClick={(e) => {
                            e.preventDefault()
                            history.push(
                              `/users/${user.walletPublicKey}/rewards/accept/${transaction.token}`
                            )
                          }}
                          className="border rounded border-green-00ffad ml-5 inline-block text-sm px-3 py-1 font-normal"
                        >
                          claim
                        </span>
                      )}
                  </>
                )}
              </>
            )}
          </h2>
          {/* ID SubSection */}
          <h3 className="font-colfax text-base font-light text-gray-969696 md:text-lg">
            {transaction.user.username}
          </h3>
        </div>
      </div>
      {/* Budget Section */}
      <div className="flex flex-col items-end justify-center">
        <div className="flex justify-between">
          {isNft ? (
            <>
              {hasMetadata && (
                <div className="max-w-[32px] max-h-[32px] mb-2">
                  <img src={nftMetadata.image} className="w-full h-full rounded-sm" alt="" />
                </div>
              )}
            </>
          ) : (
            <>
              {transaction?.dollarAmount > 0 && (
                <p
                  className={clsx(
                    transaction.action === 'sent' ? 'text-green-00ffad' : 'text-yellow-fcff6a',
                    'font-colfax text-lg font-bold md:text-2xl'
                  )}
                >
                  {transaction.dollarAmount > 0
                    ? formatDollarAmount(transaction.dollarAmount)
                    : '\u00A0'}
                </p>
              )}
            </>
          )}
        </div>

        <p className="font-colfax text-base font-normal text-gray-969696 md:text-lg">
          {formatNumber(transaction.amount || 0)}{' '}
          {tokenName.length > 10 ? tokenName.slice(0, 10) + '...' : tokenName}
        </p>
      </div>
    </Link>
  )
}

export default TransactionItem
