import { useParams, useLocation } from 'react-router'
import { Link } from 'react-router-dom'

import { skipToken } from '@reduxjs/toolkit/query/react'
import { Helmet } from 'react-helmet'

import Loading from '../../../components/Loading'
import ErrorDisplay from '../../../components/ErrorDisplay'

import { useGetListingQuery } from '../../../services/listingApi'
import { useGetSocialCardQuery } from '../../../services/socialCardApi'

import styles from './NFT.module.css'
import twitterGrayIcon from '../../../assets/icons/twitterGray.svg'
import verifiedIcon from '../../../assets/icons/verified.svg'
import downloadIcon from '../../../assets/icons/download.svg'
import backWhiteArrow from '../../../assets/icons/backWhiteArrow.svg'

import { heyWalletTwitterUsername } from '../../../config'
import { getSOLFromLamport, shortenAddress } from '../../../utils/common'

const NFTPage = () => {
  const { id } = useParams()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const mintParam = queryParams.get('mint')
  const priceParam = queryParams.get('price')
  let cardUrl
  let mint
  let price

  const {
    data: listing,
    isError: isListingError,
    isLoading: isListingLoading,
    error: listingError,
  } = useGetListingQuery(id)

  const isNewListing = !isListingLoading && isListingError && priceParam && mintParam
  if (isNewListing) {
    mint = mintParam
    price = priceParam
  } else if (!isListingLoading && !isListingError && listing) {
    mint = listing.mint
    price = listing.price.toString()
  }

  const res = useGetSocialCardQuery(mint && price ? { mint, price } : skipToken)
  if (!res.isError && !res.isLoading && res.data) {
    cardUrl = res.data.url
  }

  const pageUrl = window.location.origin + window.location.pathname

  const floorDiff = listing
    ? Math.round(((listing.floorPrice - listing.price) / listing.floorPrice) * 100)
    : null

  const solScan = (account) => `https://solscan.io/account/${account}`

  return (
    <div className={`flex  h-full flex-col items-center pt-32 ${styles.container}`}>
      {isListingError ? (
        <>
          <Helmet defer={false}>
            <meta charSet="utf-8" />
            <title>Hey Wallet!</title>
            <meta property="og:site_name" content="Hey Wallet" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={`Buy an NFT`} />
            <meta property="og:url" content={pageUrl} />
            <meta property="og:image" content={cardUrl} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={`@${heyWalletTwitterUsername}`} />
            <meta name="twitter:title" content={`Buy an NFT`} />
            <meta name="twitter:url" content={pageUrl} />
            <meta name="twitter:image" content={cardUrl} />
            <meta name="twitter:site" content={`@${heyWalletTwitterUsername}`} />
          </Helmet>
          <ErrorDisplay error={listingError} />
        </>
      ) : isListingLoading || !listing ? (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="description" content="Loading Hey Wallet" />
            <title>Hey Wallet!</title>
          </Helmet>
          <Loading width="100" screenCentered />
        </>
      ) : (
        <>
          <Helmet defer={false}>
            <meta charSet="utf-8" />
            <title>{listing.mint} - Hey Wallet</title>
            <meta
              name="description"
              content={`This is the wallet for ${listing.mint}. You can view all the owner's tokens, NFTs, and transactions.`}
            />
            <meta property="og:site_name" content="Hey Wallet" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={`Buy ${listing.name} NFT`} />
            <meta property="og:url" content={pageUrl} />
            <meta property="og:image" content={cardUrl} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={`@${heyWalletTwitterUsername}`} />
            <meta name="twitter:title" content={`Buy ${listing.name} NFT`} />
            <meta name="twitter:url" content={pageUrl} />
            <meta name="twitter:image" content={cardUrl} />
            <meta name="twitter:site" content={`@${heyWalletTwitterUsername}`} />
          </Helmet>

          <div className="flex-col items-start lg:items-center text-base font-medium mb-96">
            <Link to={`/nfts/`}>
              <div className="flex items-center">
                <img className="w-4 h-3 mb-1 mx-2" src={backWhiteArrow} alt="" />
                <div className="text-2xl font-bold">NFT Marketplace</div>
              </div>
            </Link>
            <div className="max-h-[396px] aspect-square my-8">
              <img className="rounded-[24px]" src={listing.imageUrl} alt="" />
            </div>
            <h1 className="text-2xl font-bold">{listing.name}</h1>
            <div className="flex items-center mb-8">
              {listing.verified ? (
                <>
                  <h2 style={{ color: 'rgb(0, 255, 173)' }}>{listing.projectDisplayName}</h2>
                  <img className="ml-2 h-[18px]" src={verifiedIcon} alt="" />
                </>
              ) : (
                <div className="flex items-center">
                  <h2 className="text-[#A2A3A3]">{listing.projectDisplayName}</h2>
                  <div className="font-bold self-top rounded text-xs px-1.5 pt-0.5 bg-zinc-900 text-zinc-400 ml-2">
                    Collection unverified
                  </div>
                </div>
              )}
              {listing.projectTwitterUrl && (
                <a href={listing.projectTwitterUrl} target="_blank" rel="noreferrer">
                  <img className="ml-3 h-4 w-5" src={twitterGrayIcon} alt="" />
                </a>
              )}
            </div>
            <div
              className="px-4 py-5 rounded-[24px] mb-8"
              style={{ backgroundColor: 'rgb(35, 36, 36)' }}
            >
              {listing.status === 'LISTED' ? (
                <>
                  <div className="text-zinc-400">Current Price</div>
                  <h1 className="text-2xl font-bold mb-4">{listing.price} SOL</h1>
                  <div className="text-zinc-400">Floor Price</div>
                  <h2 className="text-xl font-400 mb-4">{listing.floorPrice} SOL</h2>
                  {floorDiff && floorDiff > 0 ? (
                    <>
                      <div className="flex items-center mb-2" style={{ color: 'rgb(255, 0, 229)' }}>
                        <img className="w-[14px] h-[14px] mb-1" src={downloadIcon} alt="" />
                        <div className="font-bold ml-2">{`${Math.abs(
                          floorDiff,
                        )}% below the current floor price`}</div>
                      </div>
                      <div className="text-zinc-400 mb-6">
                        Snatch this NFT! Right now it&apos;s {Math.abs(floorDiff)}% below the
                        current floor price at other major marketplaces.
                      </div>
                    </>
                  ) : null}

                  <a
                    href={`https://twitter.com/intent/tweet?in_reply_to=${listing.tweetId}&text=%40${heyWalletTwitterUsername} buy for ${listing.price} SOL`}
                    className="block rounded-[36px] pt-5 pb-4 text-center w-full"
                    style={{ backgroundColor: 'rgb(0, 255, 173)' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="text-2xl font-bold" style={{ color: 'rgb(0, 103, 70)' }}>
                      Buy Now
                    </span>
                  </a>
                </>
              ) : (
                <div className="text-center">
                  {listing.status === 'SOLD' ? (
                    <>This item has been sold</>
                  ) : (
                    <>This item has been de-listed</>
                  )}
                </div>
              )}
            </div>
            <div
              className="px-4 py-5 rounded-[24px] mb-8"
              style={{ backgroundColor: 'rgb(35, 36, 36)' }}
            >
              <div className="text-zinc-400 mb-4">Seller</div>
              <div className="flex items-center justify-between">
                <Link to={`/@${listing.owner.username}`}>
                  <div className="flex items-center">
                    <img
                      className="h-10 w-10 mr-3 rounded-full"
                      src={listing.owner.imageUrl}
                      alt=""
                    />
                    <div className="flex-col mr-3">
                      <div className="break-all">{listing.owner.name}</div>
                      <div className="text-zinc-400 text-sm">@{listing.owner.username}</div>
                    </div>
                  </div>
                </Link>
                <a
                  href={`https://twitter.com/${listing.owner.username}`}
                  target="_blank"
                  rel="noreferrer"
                  className="bg-zinc-600 rounded-full p-3 min-w-max"
                >
                  <img className="h-4 w-4" src={twitterGrayIcon} alt="" />
                </a>
              </div>
            </div>
            {listing.status === 'SOLD' && (
              <div
                className="px-4 py-5 rounded-[24px] mb-8"
                style={{ backgroundColor: 'rgb(35, 36, 36)' }}
              >
                <div className="text-zinc-400 mb-4">Sold to</div>
                <div className="flex items-center justify-between">
                  <Link to={`/@${listing.soldTo.username}`}>
                    <div className="flex items-center">
                      <img
                        className="h-10 w-10 mr-3 rounded-full"
                        src={listing.soldTo.imageUrl}
                        alt=""
                      />
                      <div className="flex-col mr-3">
                        <div className="break-all">{listing.soldTo.name}</div>
                        <div className="text-zinc-400 text-sm">@{listing.soldTo.username}</div>
                      </div>
                    </div>
                  </Link>
                  <a
                    href={`https://twitter.com/${listing.soldTo.username}`}
                    target="_blank"
                    rel="noreferrer"
                    className="bg-zinc-600 rounded-full p-3 min-w-max"
                  >
                    <img className="h-4 w-4" src={twitterGrayIcon} alt="" />
                  </a>
                </div>
              </div>
            )}
            {listing.status === 'SOLD' && listing.rewardWinner && (
              <div
                className="px-4 py-5 rounded-[24px] mb-8"
                style={{ backgroundColor: 'rgb(35, 36, 36)' }}
              >
                <div className="text-zinc-400 mb-4">Reward winner</div>
                <div className="flex items-center justify-between">
                  <Link to={`/@${listing.rewardWinner.username}`}>
                    <div className="flex items-center">
                      <img
                        className="h-10 w-10 mr-3 rounded-full"
                        src={listing.rewardWinner.imageUrl}
                        alt=""
                      />
                      <div className="flex-col mr-3">
                        <div className="break-all">{listing.rewardWinner.name}</div>
                        <div className="text-zinc-400 text-sm">
                          @{listing.rewardWinner.username}
                        </div>
                      </div>
                    </div>
                  </Link>
                  {getSOLFromLamport(listing.rewardAmount)} SOL
                </div>
              </div>
            )}
            <div
              className="px-4 py-5 rounded-[24px] mb-8"
              style={{ backgroundColor: 'rgb(35, 36, 36)' }}
            >
              <div className="text-zinc-400">Fees</div>
              <div className="flex-col">
                <div className="flex items-center justify-between mt-4">
                  <div className="break-all mr-2">Artist Royalties</div>
                  <div className="text-zinc-400 text-sm">
                    {listing.royalties && listing.royalties > 0
                      ? `${listing.royalties / 100}%`
                      : '-'}
                  </div>
                </div>
                <div className="flex items-center justify-between mt-4">
                  <div className="break-all mr-2">Marketplace fee</div>
                  <div className="text-zinc-400 text-sm">{'1.9%'}</div>
                </div>
                <div className="flex items-center justify-between mt-4">
                  <div className="break-all mr-2">Listing/Bidding/Cancel</div>
                  <div className="text-zinc-400 text-sm">{'FREE'}</div>
                </div>
              </div>
            </div>
            <div
              className="px-4 py-5 rounded-[24px] mb-8"
              style={{ backgroundColor: 'rgb(35, 36, 36)' }}
            >
              <div className="text-zinc-400 mb-4">NFT Details</div>
              <div className="flex-col">
                <div className="flex items-center justify-between mb-4">
                  <div>Mint Address</div>
                  <div className="text-zinc-400 text-sm">
                    <a href={solScan(listing.mint)} target="_blank" rel="noreferrer">
                      {shortenAddress(listing.mint)}
                    </a>
                  </div>
                </div>
                {listing.collection && (
                  <div className="flex items-center justify-between mb-4">
                    <div>On-chain Collection</div>
                    <div className="text-zinc-400 text-sm">
                      <a href={solScan(listing.collection)} target="_blank" rel="noreferrer">
                        {shortenAddress(listing.collection)}
                      </a>
                    </div>
                  </div>
                )}
                <div className="flex items-center justify-between mb-4">
                  <div>Token address</div>
                  <div className="text-zinc-400 text-sm">
                    <a href={solScan(listing.token)} target="_blank" rel="noreferrer">
                      {shortenAddress(listing.token)}
                    </a>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div>Owner</div>
                  <div className="text-zinc-400 text-sm">
                    <a href={solScan(listing.owner.publicKey)} target="_blank" rel="noreferrer">
                      {shortenAddress(listing.owner.publicKey)}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default NFTPage
