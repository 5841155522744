import React, { useState, useEffect, useRef, useContext } from 'react'
// import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useCombobox } from 'downshift'
import { ethers } from 'ethers'
import debounce from 'debounce-fn'

import AuthContext from '../../context/AuthContext'
import { SESSION_COOKIE_NAME } from '../../constant'
import { ApiURL, txOneToOneCommission, ethRpcUrl } from '../../config'

import {
  formatNumber,
  formatDollarAmount,
  justifyNumberString,
  amountToLamports,
  getTokenAmountFromLamport,
} from '../../utils/common'
// import SendIcon from '../../assets/icons/send-icon.png'
import SolanaIcon from '../../assets/icons/solanaIcon.svg'
import PolygonIcon from '../../assets/icons/polygon.svg'

import styles from './TokenItem.module.css'
import clsx from 'clsx'
import BigNumber from 'bignumber.js'

const RecipientInput = ({ onChange }) => {
  const [inputItems, setInputItems] = useState([])
  const { getInputProps, getItemProps, getMenuProps, isOpen, highlightedIndex } = useCombobox({
    items: inputItems,
    onInputValueChange: ({ inputValue }) => {
      debounce(() => {
        // replace whitespace with empty string
        const query = inputValue?.replace(/^@/, '').replace(/\s/g, '')
        if (query) {
          onChange(query)
          fetch(`${ApiURL}users/search/${query}`)
            .then((res) => res.json())
            .then((contacts) => {
              setInputItems(contacts)
            })
        } else {
          setInputItems([])
        }
      }, 300)()
    },
    defaultHighlightedIndex: 0,
    itemToString(item) {
      return item ? item.username : ''
    },
  })

  return (
    <div className="relative">
      <div className="relative flex flex-wrap flex-col">
        <div className={`px-4 flex bg-white rounded-md ${styles.amountInput}`}>
          <input
            className="w-full focus:outline-none"
            placeholder="@handle or address"
            {...getInputProps({
              style: {},
            })}
          />
        </div>
      </div>
      <ul
        className={`absolute w-full bg-white mt-1 shadow-md max-h-80 overflow-scroll z-50 p-0 ${
          !(isOpen && inputItems.length) && 'hidden'
        }`}
        {...getMenuProps()}
      >
        {isOpen &&
          inputItems.map((item, index) => (
            <li
              key={item.id}
              className={clsx(
                highlightedIndex === index && 'bg-blue-300',
                'py-2 px-3 shadow-sm flex gap-3 items-center',
              )}
              {...getItemProps({
                item,
                index,
              })}
            >
              <div className="flex items-center h-[32px]">
                <div className="max-w-[32px] max-h-[32px] mr-2">
                  <img src={item.profileImageUrl} className="w-full h-full rounded-sm" alt="" />
                </div>
                <div>{item.username}</div>
              </div>
            </li>
          ))}
      </ul>
    </div>
  )
}

const SOL_DOMAIN = /^(?<domain>[A-Z0-9-_]{1,63}\.sol)$/i
const ETH_DOMAIN = /^(?<domain>[A-Z0-9-_]{1,63}\.eth)$/i
const BASE58 = /^"?(?<wallet>[a-km-zA-HJ-NP-Z1-9]{43,44})"?$/
const ETH_ADDRESS = /^"?(?<wallet>(0x)?[0-9a-fA-F]{40})"?$/

const TokenSendModal = ({ token, onClose }) => {
  const history = useHistory()
  const { currentUser } = useContext(AuthContext)
  const [cookies] = useCookies([SESSION_COOKIE_NAME])

  const [amount, setAmount] = useState('')
  const [handle, setHandle] = useState(null)
  const ref = useRef(null)
  const [sendingTransaction, setSendingTransaction] = useState(false)

  const onAmountChange = (e) => {
    setAmount(e.target.value)
  }

  const scrollToTop = () => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  const onSelectedContact = (contact) => {
    const isExternal =
      BASE58.test(contact) ||
      SOL_DOMAIN.test(contact) ||
      ETH_DOMAIN.test(contact) ||
      ETH_ADDRESS.test(contact)
    setHandle({
      type: isExternal ? 'external' : 'system',
      username: contact,
    })
  }

  const getMaxSendableAmount = () => {
    const userBalance = new BigNumber(token.rawLamports)
    const networkFee = 5000
    const userBalanceWithoutFee = userBalance.minus(token.symbol === 'SOL' ? networkFee : 0)
    const maxSendableAmount = userBalanceWithoutFee
      .multipliedBy(100)
      .dividedBy(100 + txOneToOneCommission / 100)

    return maxSendableAmount.toFixed(0, BigNumber.ROUND_DOWN)
  }

  const getMaxSendableAmountEth = (balance, gasPrice) => {
    const txOneToOneCommission = 300
    const gasLimit = 21000

    const balanceWithoutFee = gasPrice ? balance.sub(gasPrice.mul(gasLimit).mul(2)) : balance
    return balanceWithoutFee
      .mul(100)
      .div(100 + txOneToOneCommission / 100)
      .add(1)
  }

  const calculateHwFee = () => {
    if (!amount) return 0
    const userBalance = new BigNumber(amount)
    return userBalance.multipliedBy(txOneToOneCommission / 10000).toString()
  }

  const onMaxAmount = () => {
    if (token.chainId === 'ETH') {
      const ethProvider = new ethers.JsonRpcProvider(ethRpcUrl)
      ethProvider.getGasPrice().then((gasPrice) => {
        console.log(gasPrice.toString())
        const max = getMaxSendableAmountEth(token.rawLamports, gasPrice)
        if (max.gt(0)) {
          const bal = ethers.formatEther(max)
          setAmount(bal)
        } else {
          setAmount('0')
        }
      })
    } else {
      const maxAmount = getMaxSendableAmount(token.rawLamports)
      const bal = getTokenAmountFromLamport(maxAmount, token.decimals, token.decimals)
      setAmount(bal)
    }
  }

  const amountRegex = new RegExp('^[0-9]*\\.?[0-9]+$')

  const handleSend = (e) => {
    setSendingTransaction(true)
    e.preventDefault()
    if (!currentUser) {
      alert('Please login to send tokens')
      return
    }
    if (!amount || !amountRegex.test(amount)) {
      alert('Enter a valid amount: only numbers and dot characters are allowed (e.g. 0.33)')
      return
    }
    if (
      amountToLamports(amount, token.decimals).comparedTo(
        amountToLamports(token.lamports, token.decimals),
      ) > 0
    ) {
      alert('Insufficient balance')
      return
    }

    fetch(`${ApiURL}actions/send`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies[SESSION_COOKIE_NAME]}`,
      },
      body: JSON.stringify({
        handle: handle,
        amount,
        ticker: token.symbol,
        chainId: token.chainId,
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          if (data.txId) {
            history.push(`/transactions/${data.txId}`)
            onClose()
            setAmount('')
          } else {
            alert('Failed to send')
          }
        })
      } else {
        alert('Failed to send')
      }
    })
  }

  const isEthereumToken = token.chainId === 'ETH' && token.symbol === 'MATIC'

  useEffect(() => {
    scrollToTop()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div
        className={`fixed ${styles.sendModalBg}`}
        onClick={onClose}
        onKeyUp={() => null}
        role="button"
        tabIndex={0}
      ></div>
      <div className={`absolute ${styles.sendModal}`} ref={ref}>
        <div className="bg-[#37373C] px-6 py-6 rounded-xl flex flex-col items-center relative">
          <p className="text-gray-ececec w-full font-bold text-center text-2xl">Send a token</p>
          <div className="flex text-white justify-center space-x-3 items-center mt-6">
            <div>
              <img
                src={isEthereumToken ? PolygonIcon : token.logoURI}
                alt={token.name}
                className="h-14 w-14 rounded-full"
              />
            </div>
            <div>
              <div className="font-bold">{token.name}</div>
            </div>
          </div>
          <div className="w-full mt-6 flex flex-col flex-wrap justify-between">
            <RecipientInput onChange={onSelectedContact} />

            <div className="flex space-x-2">
              <div
                className={`${
                  token.chainId === 'SOL' ? 'w-3/4' : 'w-full'
                } mt-2 px-4 flex bg-white rounded-md ${styles.amountInput}`}
              >
                <input
                  className={`${token.chainId === 'SOL' ? 'w-3/4' : 'w-full'} focus:outline-none`}
                  type="number"
                  placeholder="Amount"
                  value={amount}
                  onChange={onAmountChange}
                />
                <span className="w-1/4 text-[#27272A] text-right font-medium">
                  <span
                    className={
                      token.symbol.length > 4
                        ? token.symbol.length > 7
                          ? 'text-xs'
                          : 'text-sm'
                        : 'mt-1 inline-block'
                    }
                  >
                    {token.symbol.substr(0, 8)}
                  </span>
                </span>
              </div>
              <button
                className="w-1/4 rounded-md px-2 py-3 mt-2 font-medium text-white bg-[#209BF0]"
                onClick={onMaxAmount}
              >
                Max
              </button>
            </div>
            <div className="mt-4 font-normal text-zinc-400">
              {token.chainId === 'SOL' && (
                <div>
                  Network Fee: <span className="text-white">0.000005 SOL</span>
                </div>
              )}
              {amount && (
                <div className="mt-2">
                  Hey Wallet Fee:{' '}
                  <span className="text-white">
                    {calculateHwFee()} {token.symbol}
                  </span>
                </div>
              )}
            </div>
            <button
              className={
                'w-full rounded-md px-2 py-3 mt-3 font-medium text-white ' +
                (sendingTransaction ? 'bg-[#696969]' : 'bg-[#209BF0]')
              }
              onClick={handleSend}
              disabled={sendingTransaction}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

const TokenItem = ({ asset }) => {
  // const { currentUser } = useContext(AuthContext)
  const [isSendOpen, setIsSendOpen] = useState(false)
  const parsedDollarAmount = parseFloat(asset.dollar)
  const isEthereumToken = asset.chainId === 'ETH' && asset.symbol === 'MATIC'

  // const { id } = useParams()
  // const username = useMemo(() => (id?.[0] === '@' ? id.slice(1) : id), [id])
  const isBalanceZero = ['0', '0.00'].includes(asset.lamports.toString())

  return (
    <>
      <div className="mb-4 flex w-full items-center justify-between">
        <div className="flex">
          <div className="mr-3 w-14 text-center md:w-75px">
            <img
              className="h-14 overflow-hidden rounded-full inline-block md:h-75px"
              src={isEthereumToken ? PolygonIcon : asset.logoURI}
              alt="Logo"
            />
          </div>

          <div className="flex flex-col justify-center">
            {/* Type SubSection */}

            <h2 className="font-colfax text-lg font-bold text-gray-50 md:text-2xl flex items-start">
              <span>{asset.name}</span>
              <span className="ml-2 -mt-0.5 inline-block bg-[#333] bg-opacity-80 rounded-lg px-2">
                {asset.chainId === 'ETH' && (
                  <img className="w-4 inline" src={PolygonIcon} alt="Ethereum" />
                )}
                {asset.chainId === 'SOL' && (
                  <img className="w-4 inline" src={SolanaIcon} alt="Ethereum" />
                )}
              </span>
            </h2>

            {/* ID SubSection */}
            <h3 className="font-colfax text-base font-light text-gray-969696 md:text-lg">
              {formatNumber(justifyNumberString(asset.lamports))} {asset.symbol}
            </h3>
          </div>
        </div>

        {/* Budget Section */}
        <div className="flex items-center justify-center">
          <p className="font-colfax text-lg font-bold text-green-00ffad md:text-2xl">
            {isBalanceZero ? '--' : asset.dollar ? formatDollarAmount(parsedDollarAmount) : '$0.00'}
          </p>
          {/* {currentUser && currentUser.username === username && !isBalanceZero && (
            <div className="ml-3">
              <button
                onClick={() => {
                  setIsSendOpen(true)
                }}
              >
                <img src={SendIcon} alt="" className="w-8" />
              </button>
            </div>
          )} */}
        </div>
      </div>
      {isSendOpen && !isBalanceZero && (
        <TokenSendModal
          token={asset}
          onClose={() => {
            setIsSendOpen(false)
          }}
        />
      )}
    </>
  )
}

export default TokenItem
