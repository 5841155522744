import { useCallback, useRef, useState } from 'react'

import useWindowListener from './useWindowListener'

const useRect = () => {
  const ref = useRef(null)
  const [rect, setRect] = useState()

  const set = useCallback(() => setRect(ref.current?.getBoundingClientRect()), [])

  useWindowListener('resize', set)
  useWindowListener('scroll', set, true)

  return [rect, ref]
}

export default useRect
