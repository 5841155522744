import { AutoRefresh, IdleRefresh, offlineRetryInterval } from '../../../constant'

/**
 * Returns the number of ms between polling a service, or `undefined` to disable polling.
 * `undefined` is accepted as the RTK Query `pollingInterval` or the second argument passed to
 * `react-useinterval`'s `useInterval`. Returning 0 would mean 0ms delay for `useInterval`.
 */
export const servicePollingInterval = (
  isActiveTab,
  serviceName,
  isServiceOffline,
  anyBesidesServiceOffline,
) => {
  const getMillisecondInterval = () => {
    if (isActiveTab) {
      if (isServiceOffline) return offlineRetryInterval
      if (anyBesidesServiceOffline) return IdleRefresh[serviceName]
      return AutoRefresh[serviceName]
    } else {
      return undefined
    }
  }

  const msInterval = getMillisecondInterval()

  return msInterval === undefined ? msInterval : msInterval * 1000
}
