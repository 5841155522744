import { useState, useEffect, useCallback, useRef, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import { useCookies } from 'react-cookie'
import { Helmet } from 'react-helmet'
import { programs } from '@metaplex/js'

import Loading from '../../../components/Loading'

import AuthContext from '../../../context/AuthContext'
import { shortenAddress } from '../../../utils/common'
import { ApiURL } from '../../../config'
import { web3Connection } from '../../../utils/web3'
import { SESSION_COOKIE_NAME } from '../../../constant'

import twitterGrayIcon from '../../../assets/icons/twitterGray.svg'
import verifiedIcon from '../../../assets/icons/verified.svg'
import backWhiteArrow from '../../../assets/icons/backWhiteArrow.svg'

import styles from './NftDetails.module.css'

const Metadata = programs.metadata.Metadata
const MetadataData = programs.metadata.MetadataData

const NftCollectionModal = ({ nft, onUpdate, onClose }) => {
  const [name, setName] = useState('')
  const [collection, setCollection] = useState('')
  const [collections, setCollections] = useState([])
  const [loading, setLoading] = useState(false)
  const [collectionLoading, setCollectionLoading] = useState(false)
  const [showNewForm, setShowNewForm] = useState(false)
  const [cookies] = useCookies([SESSION_COOKIE_NAME])
  const ref = useRef(null)

  const onNameChange = (e) => {
    setName(e.target.value)
  }

  const scrollToTop = () => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  const createCollection = (e) => {
    setCollectionLoading(true)
    fetch(`${ApiURL}nfts/collections/`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies[SESSION_COOKIE_NAME]}`,
      },
      method: 'POST',
      body: JSON.stringify({
        name,
      }),
    }).then((res) => {
      if (res.ok) {
        res.json().then((res) => {
          setName('')
          setShowNewForm(false)
          refreshCollections()
          setCollection(res.mint)
        })
      }
    })
  }

  const setNftCollection = () => {
    setLoading(true)
    fetch(`${ApiURL}nfts/${nft.mint}/collection/${collection}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies[SESSION_COOKIE_NAME]}`,
      },
      method: 'PUT',
    }).then((res) => {
      if (res.ok) {
        res.json().then(() => {
          setCollection('')
          setLoading(false)
          onUpdate()
        })
      }
    })
  }

  const onCollectionSelect = (e) => {
    setCollection(e.target.value)
    if (e.target.value === 'new') {
      setShowNewForm(true)
    } else {
      setName('')
      setShowNewForm(false)
    }
  }

  const refreshCollections = useCallback(() => {
    fetch(`${ApiURL}nfts/collections/`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies[SESSION_COOKIE_NAME]}`,
      },
    }).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          setCollections(data)
        })
      }
    })
  }, [cookies])

  useEffect(() => {
    if (collection && collection !== 'new' && collections) {
      if (collections.find((c) => c.mintAddress === collection)) {
        setCollectionLoading(false)
      } else {
        refreshCollections()
      }
    }
  }, [collection, collections, refreshCollections])

  useEffect(() => {
    refreshCollections()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    scrollToTop()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="text-black">
      <div
        className={`fixed ${styles.modalBg}`}
        onClick={onClose}
        onKeyUp={() => null}
        role="button"
        tabIndex={0}
      ></div>
      <div className={`absolute ${styles.modal}`} ref={ref}>
        <div
          className={`bg-[#37373C] px-6 py-6 rounded-xl flex flex-col items-center relative ${styles.modalCnt}`}
        >
          <p className="text-gray-ececec w-full font-bold text-center text-2xl">
            Set the collection of the NFT
          </p>
          <div className="flex text-white justify-center space-x-3 items-center mt-6">
            <div>
              <img src={nft.imageUrl} alt={nft.name} className={styles.modalImage} />
            </div>
            <div>
              <div className="font-bold">{nft.name}</div>
            </div>
          </div>
          {loading ? (
            <Loading
              className="absolute self-center w-14"
              style={{
                left: 'calc(50% - 28px)',
                top: 'calc(60%)',
              }}
            />
          ) : (
            <>
              <div className="w-full mt-10 flex flex-wrap justify-between">
                {collectionLoading ? (
                  <Loading
                    className="absolute self-center w-14"
                    style={{
                      left: 'calc(50% - 28px)',
                      top: 'calc(60%)',
                    }}
                  />
                ) : (
                  <>
                    <select
                      onChange={onCollectionSelect}
                      value={collection}
                      className="text-[#666] h-12 w-full font-normal mb-5"
                    >
                      <option value="" key="">
                        select a collection
                      </option>
                      <option value="new" key="new">
                        [create new collection]
                      </option>
                      {collections.map((collection) => (
                        <option value={collection.mintAddress} key={collection.mintAddress}>
                          {collection.name} ({collection.mintAddress.substr(0, 6)})
                        </option>
                      ))}
                    </select>

                    {showNewForm && (
                      <div className={`w-full flex space-x-2`}>
                        <input
                          className="w-3/4 py-3 px-4 focus:outline-none bg-white rounded-md"
                          placeholder="Collection name"
                          value={name}
                          onChange={onNameChange}
                        />
                        <button
                          className={`w-1/4  rounded-md text-center font-medium ${
                            name.length < 3 || collectionLoading
                              ? 'cursor-default bg-[#ccffef] text-black'
                              : 'bg-green-00ffad'
                          }`}
                          onClick={createCollection}
                          disabled={name.length < 3 || collectionLoading}
                        >
                          Create
                        </button>
                      </div>
                    )}

                    {!showNewForm && (
                      <button
                        className={`w-full rounded-md px-2 py-3 font-medium ${
                          !collection ? 'cursor-default bg-[#ccffef] text-black' : 'bg-green-00ffad'
                        }`}
                        onClick={setNftCollection}
                        disabled={!collection}
                      >
                        Next
                      </button>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const NftDetails = () => {
  const history = useHistory()
  const { mint } = useParams()
  const { currentUser } = useContext(AuthContext)

  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isTrackingCollection, setIsTrackingCollection] = useState(false)
  const [isAuthority, setIsAuthority] = useState(false)
  const [error, setError] = useState(null)
  const [resp, setResp] = useState(null)
  const [nftMetadata, setNftMetadata] = useState({})
  const [showCollectionModal, setShowCollectionModal] = useState(false)
  const [collectionName, setCollectionName] = useState('')

  const solScan = (account, kind = 'account') => `https://solscan.io/${kind}/${account}`

  const item = resp && resp.item ? resp.item : null

  const refreshNft = useCallback(async () => {
    fetch(`${ApiURL}bids/${mint}`)
      .then((res) => {
        if (res.status === 404) {
          setError("NFT with this mint wasn't found")
          setIsPageLoading(false)
        } else {
          return res.json()
        }
      })
      .then((res) => {
        if (res) {
          setResp(res)
          setIsPageLoading(false)
        }
      })
  }, [mint])

  const goBack = () => {
    return history.goBack(-1)
  }

  useEffect(() => {
    refreshNft()
  }, [refreshNft])

  const fetchTokenMetadata = useCallback(
    async (mint) => {
      const pda = await Metadata.getPDA(mint)
      const account = await web3Connection.getAccountInfo(pda)
      const metadata = MetadataData.deserialize(account.data)
      if (metadata.updateAuthority === currentUser?.walletPublicKey) {
        setIsAuthority(true)
      }
      try {
        const offchainMeta = await fetch(metadata.data.uri)
        if (offchainMeta.status === 200) {
          const offchainMetaJson = await offchainMeta.json()
          setNftMetadata(offchainMetaJson)
        }
      } catch (e) {
        console.log(e)
      }
      setIsPageLoading(false)
    },
    [currentUser],
  )

  // somewhat duplicate to fetchTokenMetadata but they are a bit
  // different in subtle ways, so much easier to just create a separate
  // function for now. we'll probably create a universal function for
  // fetching metadata during refactor
  const fetchCollectionName = useCallback(async (mint) => {
    const pda = await Metadata.getPDA(mint)
    const account = await web3Connection.getAccountInfo(pda)
    if (!account) return
    const metadata = MetadataData.deserialize(account.data)
    if (metadata.data.uri) {
      const offchainMeta = await fetch(metadata.data.uri)
      if (offchainMeta.status === 200) {
        try {
          const meta = await offchainMeta.json()
          if (meta.name) {
            setCollectionName(meta.name)
            return
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
    if (metadata.data.name) {
      setCollectionName(metadata.data.name)
    }
  }, [])

  useEffect(() => {
    if (resp) {
      fetchTokenMetadata(resp.item.mintPublicKey)
    }
  }, [resp, fetchTokenMetadata])

  useEffect(() => {
    if (isTrackingCollection) {
      if (!item.collection) {
        refreshNft()
      }
    }
  }, [item, refreshNft, isTrackingCollection])

  useEffect(() => {
    if (item && item.collection) {
      fetchCollectionName(item.collection)
    }
  }, [item, fetchCollectionName])

  return (
    <div className={`mx-auto w-full max-w-[414px] text-white`}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Loading Hey Wallet" />
        <title>Hey Wallet!</title>
      </Helmet>
      {isPageLoading ? (
        <>
          <Loading width="100" screenCentered />
        </>
      ) : (
        <>
          {error ? (
            <div className="flex-col items-start px-4 pt-12 pb-2 text-base font-medium lg:items-center">
              <button onClick={goBack}>
                <div className="mb-1 flex items-center">
                  <img className="mx-2 mb-1 h-3 w-4" src={backWhiteArrow} alt="" />
                  <div className="text-2xl font-medium">Back</div>
                </div>
              </button>
              <div className="pt-10 text-center">{error}</div>
            </div>
          ) : (
            <>
              {item ? (
                <>
                  <div className="flex-col items-start px-4 pt-12 pb-2 text-base font-medium lg:items-center">
                    {showCollectionModal && (
                      <NftCollectionModal
                        nft={item}
                        onUpdate={() => {
                          setIsTrackingCollection(true)
                          setShowCollectionModal(false)
                        }}
                        onClose={() => {
                          setShowCollectionModal(false)
                        }}
                      />
                    )}
                    <button onClick={goBack}>
                      <div className="mb-1 flex items-center">
                        <img className="mx-2 mb-1 h-3 w-4" src={backWhiteArrow} alt="" />
                        <div className="text-2xl font-medium">Back</div>
                      </div>
                    </button>
                    <div className="my-8 aspect-square max-h-[396px]">
                      {nftMetadata.animation_url ? (
                        <video
                          controls
                          autoPlay
                          loop
                          muted
                          playsInline
                          className="h-full w-full object-contain rounded-lg"
                          style={{
                            backgroundColor: '#1B1B1B',
                          }}
                        >
                          <source src={nftMetadata.animation_url} type="video/mp4" />
                        </video>
                      ) : (
                        <img className="rounded-[24px]" src={item.imageUrl} alt="" />
                      )}
                    </div>
                    <h1 className="mx-2 text-2xl font-medium">{item.name}</h1>
                    <div className="mx-2 mb-8 flex items-center">
                      {item.verified ? (
                        <>
                          <h2 className="font-light text-green-00ffad">
                            {item.projectDisplayName}
                          </h2>
                          <img className="ml-2 h-[18px]" src={verifiedIcon} alt="" />
                        </>
                      ) : (
                        <h2 className="font-light text-[rgb(162,163,163)]">
                          {collectionName ? (
                            <Link to={`/nfts/collection/${item.collection}`}>{collectionName}</Link>
                          ) : (
                            item.projectDisplayName
                          )}
                          <div className="inline-block font-bold self-top rounded text-xs px-1.5 pt-0.5 bg-zinc-900  ml-2">
                            Unverified
                          </div>
                        </h2>
                      )}
                      {item.projectTwitterUrl && (
                        <a href={item.projectTwitterUrl} target="_blank" rel="noreferrer">
                          <img className="ml-3 h-4 w-5" src={twitterGrayIcon} alt="" />
                        </a>
                      )}
                    </div>

                    <div className="mb-5 max-w-[396px]">
                      <div className="mb-8 rounded-[24px] bg-[rgb(35,36,36)] px-4 py-5 font-normal">
                        <div className="mb-4 font-normal text-zinc-400">Owner</div>
                        <div className="flex items-center justify-between">
                          {item.owner.username ? (
                            <>
                              <Link to={`/@${item.owner.username}`}>
                                <div className="flex items-center">
                                  <img
                                    className="mr-3 h-10 w-10 rounded-full"
                                    src={item.owner.imageUrl}
                                    alt=""
                                  />
                                  <div className="mr-3 flex-col">
                                    <div className="break-all">{item.owner.name}</div>
                                    <div className="text-sm text-zinc-400">
                                      @{item.owner.username}
                                    </div>
                                  </div>
                                </div>
                              </Link>
                              <a
                                href={`https://twitter.com/${item.owner.username}`}
                                target="_blank"
                                rel="noreferrer"
                                className="min-w-max rounded-full bg-zinc-600 p-3"
                              >
                                <img className="h-4 w-4" src={twitterGrayIcon} alt="" />
                              </a>
                            </>
                          ) : (
                            <div>
                              <a
                                href={solScan(item.owner.publicKey)}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {shortenAddress(item.owner.publicKey)}
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="mb-[23px] max-w-[396px] rounded-3xl bg-[rgb(35,36,36)] px-4 py-5">
                      <div className="mb-4 font-normal text-zinc-400">NFT Details</div>
                      <div className="flex-col">
                        <div className="mb-4 flex items-center justify-between">
                          <div>Mint Address</div>
                          <div className="text-sm text-zinc-400">
                            <a href={solScan(item.mint)} target="_blank" rel="noreferrer">
                              {shortenAddress(item.mint)}
                            </a>
                          </div>
                        </div>
                        {(isAuthority || item.collection) && (
                          <div className="mb-4 flex items-center justify-between">
                            <div>On-chain Collection</div>
                            {item.collection ? (
                              <div className="text-sm text-zinc-400">
                                <a href={solScan(item.collection)} target="_blank" rel="noreferrer">
                                  {shortenAddress(item.collection)}
                                </a>
                              </div>
                            ) : (
                              <div className="text-sm text-zinc-400">
                                <button
                                  onClick={() => {
                                    setShowCollectionModal(true)
                                  }}
                                >
                                  Set collection
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                        <div className="mb-4 flex items-center justify-between">
                          <div>Token address</div>
                          <div className="text-sm text-zinc-400">
                            <a href={solScan(item.token)} target="_blank" rel="noreferrer">
                              {shortenAddress(item.token)}
                            </a>
                          </div>
                        </div>
                        <div className="flex items-center justify-between">
                          <div>Owner</div>
                          <div className="text-sm text-zinc-400">
                            <a
                              href={solScan(item.owner.publicKey)}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {shortenAddress(item.owner.publicKey)}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-[23px] max-w-[396px] rounded-3xl bg-[rgb(35,36,36)] px-4 py-5">
                      <div className="mb-4 font-normal text-zinc-400">Fees & Rebates</div>
                      <div className="flex-col">
                        <div className="flex items-center justify-between">
                          <div>Royalties</div>
                          <div className="text-sm text-zinc-400">
                            {item.royalties ? item.royalties / 100 : 0}%
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-10 mb-7 text-center font-[17px] text-[rgb(107,107,107)]">
                      Powered by{' '}
                      <a href="https://heywallet.com" className="font-bold">
                        Hey Wallet
                      </a>
                    </div>
                  </div>
                </>
              ) : null}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default NftDetails
