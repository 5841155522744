import TokenItem from '../../components/TokenItem'

const TokensList = ({ sortedAssets }) => {
  return (
    <div className="fade-focus-in w-full">
      <div className="w-full">
        {sortedAssets.length ? (
          sortedAssets.map((asset, index) => <TokenItem asset={asset} key={index} />)
        ) : (
          <p className="pt-4 pb-7 text-center text-44 font-black text-gray-4b4b4b">None</p>
        )}
      </div>
    </div>
  )
}

export default TokensList
