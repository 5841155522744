import { useEffect, useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router'
import { useCookies } from 'react-cookie'
import { ApiURL } from '../../../config'

import { SESSION_COOKIE_NAME } from '../../../constant'

import XIcon from '../../../assets/icons/x-white.svg'
import ForwardArrowIcon from '../../../assets/icons/forwardArrow.svg'

const TwitterConnect = () => {
  const history = useHistory()
  const { search } = useLocation()
  const [, setCookie] = useCookies([SESSION_COOKIE_NAME])
  const qs = useMemo(() => new URLSearchParams(search), [search])
  const [telegramError, setTelegramError] = useState(false)
  const [telegramConnect, setTelegramConnect] = useState({})

  const handleLogin = useCallback(async (e) => {
    e.preventDefault()
    const res = await fetch(`${ApiURL}twitter/oauth?redirectTo=/connect/twitter/complete`, {
      credentials: 'include',
    })
    if (res.status === 200) {
      const { url } = await res.json()
      window.location.href = url
    }
  }, [])

  useEffect(() => {
    const tempTelegramConnect = {}
    // Extracting query parameters
    for (const [key, value] of qs.entries()) {
      tempTelegramConnect[key] = value
    }

    setTelegramConnect(tempTelegramConnect)

    if (tempTelegramConnect.id && tempTelegramConnect.auth_date && tempTelegramConnect.hash) {
      const sortedKeys = Object.keys(tempTelegramConnect).sort()
      const cookieValue = sortedKeys.map((key) => `${key}=${tempTelegramConnect[key]}`).join('\n')

      setCookie('telegram-connect', cookieValue, { path: '/', maxAge: 600 })
    } else {
      // Set error state if any of the required parameters are missing
      setTelegramError(true)
    }

    fetch(`${ApiURL}telegram/check`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(tempTelegramConnect),
    })
  }, [history, setCookie, qs])

  return (
    <div className="fade-focus-in mb-14 flex max-h-full w-full flex-col items-center justify-center px-5 pt-32 pb-5 sm:w-700px sm:px-0">
      <Helmet>
        <title>Connect your Twitter/X</title>
        <meta
          name="description"
          content="Log into your Hey Wallet by entering your Twitter handle."
        />
      </Helmet>
      <h1 className="font-colfax text-center text-56 font-black leading-none text-white">
        Connect your Twitter/X
      </h1>
      {telegramError ? (
        <div className="text-center mt-14">
          <p className="text-lg text-white">
            There was a problem connecting with your Telegram account.
          </p>
          <p className="text-lg text-white mt-8">
            Please close this window, restart your Telegram app, and try connecting again.
          </p>
        </div>
      ) : (
        <form className="fade-focus-in mb-14">
          <div className="max-w-sm mx-auto mt-16 text-center">
            <p className="text-white text-md font-medium">Your Telegram Account:</p>
            <div className="flex items-center justify-center bg-white/10 p-4 mt-4">
              <img src={telegramConnect.photo_url} className="h-12 w-12" alt="" />
              <div className="ml-4 flex-col items-center justify-center text-left">
                <p className="text-white text-lg font-bold">
                  {telegramConnect.first_name + ' ' + telegramConnect.last_name}{' '}
                </p>
                {telegramConnect.username ? (
                  <p className="text-white/50 text-md font-medium">@{telegramConnect.username}</p>
                ) : (
                  <p className="text-white/50 text-md font-medium">{telegramConnect.id}</p>
                )}
              </div>
            </div>
          </div>

          <p className="text-lg text-white text-center mt-12">
            Next step: Connect your Twitter/X account to receive and claim crypto rewards
          </p>

          <div className="text-center px-5">
            <button
              className={`relative mb-[72px] inline-block w-full sm:w-[400px] rounded-[44px] px-4 py-4 text-sm sm:text-lg font-bold mt-12`}
              style={{ backgroundColor: '#1c9bf0' }}
              target="_blank"
              rel="noreferrer"
              onClick={handleLogin}
            >
              <span className="flex items-center justify-center">
                <img src={XIcon} className="ml-auto h-6 w-7" alt="" />
                <span className="ml-4 mr-8 mt-1 text-white">Connect your Twitter/X</span>
                <img
                  src={ForwardArrowIcon}
                  className="ml-auto lg:mr-8 h-[22px] w-[14px]"
                  style={{ color: '#0F486F' }}
                  alt=""
                />
              </span>
            </button>
          </div>
        </form>
      )}
    </div>
  )
}

export default TwitterConnect
