import { useContext } from 'react'
import { Helmet } from 'react-helmet'

import AuthContext from '../../../context/AuthContext'

import TweetButton from '../../../components/TweetButton'

const Welcome = () => {
  const { currentUser } = useContext(AuthContext)

  return (
    <div className="flex w-full h-full flex-col items-center pt-40 md:w-700px md:px-0">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Verification - Hey Wallet</title>
        <meta
          name="description"
          content="Verify your Twitter handle by tweeting this special code. This will link your profile to your wallet."
        />
      </Helmet>
      <div className="fade-focus-in flex flex-col items-center pb-6">
        <div className="mb-14">
          <h1 className="font-colfax text-center text-56 font-black leading-none tracking-tight text-white">
            All set. Welcome to Hey Wallet{currentUser && `, ${currentUser?.username}`}!
          </h1>
        </div>
        <div className="slide-in-blurred-top">
          <TweetButton
            to={`/${currentUser ? currentUser.username : ''}`}
            text={`Launch Hey Wallet`}
          />
        </div>
      </div>
    </div>
  )
}

export default Welcome
