import { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { TwitterTweetEmbed } from 'react-twitter-embed'

import Loading from '../../../components/Loading'

import { ApiURL } from '../../../config'
import { formatDollarAmount, formatDate } from '../../../utils/common'

import outgoingTXIcon from '../../../assets/icons/outgoingTX.svg'
import solscanActiveIcon from '../../../assets/icons/solscanActive.svg'
import solscanInactiveIcon from '../../../assets/icons/solscanInactive.svg'
import polygonIcon from '../../../assets/icons/polygon.svg'
import solanaFmIcon from '../../../assets/icons/solanafm.png'
import warningIcon from '../../../assets/icons/warning.svg'

const TransactionPage = () => {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [transaction, setTransaction] = useState(null)
  const [refreshInterval, setRefreshInterval] = useState(null)

  const getTxStatus = (status) => {
    switch (status) {
      case 'CONFIRMED':
        return { style: 'bg-green-00ffad', text: 'Confirmed on the blockchain', txText: 'Sent' }
      case 'PENDING_ATA':
        return {
          style: 'bg-[rgb(244,190,84)] animate-pulse',
          text: 'Pending',
          txText: 'Recipient has to accept reward',
        }
      case 'INITIATED':
        return { style: 'bg-[rgb(244,190,84)] animate-pulse', text: 'Pending', txText: 'Sending' }
      case 'CONFIRMING':
        return {
          style: 'bg-[rgb(244,190,84)] animate-pulse',
          text: 'Confirming... Almost finished',
          txText: 'Sending',
        }
      case 'ERROR':
        return { style: 'bg-[rgb(218,0,65)]', text: 'Transaction Failed', txText: 'Failed to Send' }
      case 'LOW_BALANCE':
        return {
          style: 'bg-gray-100',
          text: 'Insufficient SOL in wallet',
          txText: 'Failed to Send',
        }
      default:
        return { style: 'bg-gray-100', text: '', txText: '' }
    }
  }

  const txHelper = () => {
    if (transaction) {
      if (
        transaction.status === 'ERROR' &&
        ['INSUFFICIENT_FUNDS_TX_FEE', 'INSUFFICIENT_FUNDS', 'INSUFFICIENT_FUNDS_SPL_RENT'].includes(
          transaction.errorCode,
        )
      ) {
        const txStatus = getTxStatus('LOW_BALANCE')
        if (transaction.asset !== 'SOL' && transaction.errorCode === 'INSUFFICIENT_FUNDS') {
          txStatus.text = `Insufficient ${transaction.asset} in wallet`
        } else if (transaction.errorCode === 'INSUFFICIENT_FUNDS_TX_FEE') {
          txStatus.text = 'Not enough SOL to pay for tx fee'
        } else if (transaction.errorCode === 'INSUFFICIENT_FUNDS_SPL_RENT') {
          txStatus.text = 'Not enough SOL to pay for the rent exemption fee'
        }

        return txStatus
      } else {
        return getTxStatus(transaction.status)
      }
    }
    return {}
  }

  const getTxUrl = () => {
    if (transaction && transaction.txSignature && transaction.status === 'CONFIRMED') {
      const baseUrl =
        transaction.chainId === 'ETH' ? 'https://polygonscan.com/tx/' : 'https://solscan.io/tx/'
      return `${baseUrl}${transaction.txSignature}`
    }
    return '#wait'
  }

  const lowBalance =
    transaction &&
    transaction.status === 'ERROR' &&
    ['INSUFFICIENT_FUNDS_TX_FEE', 'INSUFFICIENT_FUNDS', 'INSUFFICIENT_FUNDS_SPL_RENT'].includes(
      transaction.errorCode,
    )

  const tokenName =
    transaction &&
    (transaction.assetType === 'NFT' && transaction.metadata?.name
      ? transaction.metadata.name
      : transaction.asset)

  const fetchTransaction = useCallback(async () => {
    fetch(`${ApiURL}transactions/${id}`)
      .then((res) => {
        return res.json()
      })
      .then((res) => {
        setTransaction(res)
        setIsLoading(false)
      })
  }, [id])

  useEffect(() => {
    if (!transaction) return
    if (refreshInterval) {
      if (['CONFIRMED', 'ERROR'].includes(transaction.status)) {
        clearInterval(refreshInterval)
      }
    } else {
      if (transaction.status !== 'CONFIRMED') {
        const interval = setInterval(() => {
          fetchTransaction()
        }, 2000)
        setRefreshInterval(interval)
      }
    }
  }, [transaction, fetchTransaction, refreshInterval])

  useEffect(() => {
    fetchTransaction()
  }, [fetchTransaction])

  return (
    <>
      <div className="max-w-[428px] w-full h-full px-4 text-white pt-24 pb-6">
        {/*<div className="mb-8">
          <Search />
        </div>*/}
        {isLoading ? (
          <Loading width="100" screenCentered />
        ) : (
          <>
            {transaction.tweetId && (
              <div className="mb-8 overflow-y-hidden">
                <TwitterTweetEmbed
                  tweetId={transaction.tweetId}
                  options={{
                    hideCard: true,
                    hideThread: true,
                    theme: 'light',
                  }}
                  placeholder={
                    <div
                      className={`animate-pulse bg-white h-64 max-w-[396px] w-full rounded-3xl`}
                    ></div>
                  }
                />
              </div>
            )}

            <div className="bg-[rgb(35,36,36)] py-4 px-5 mb-8 rounded-3xl">
              <div className="flex justify-between mb-4">
                <div className="flex items-center">
                  <>
                    <img
                      src={transaction.status === 'ERROR' ? warningIcon : outgoingTXIcon}
                      className="mr-[17px] w-6 h-6"
                      alt=""
                    />
                    <span
                      className={
                        transaction.status === 'PENDING_ATA' ? 'text-sm mr-2' : 'text-lg mr-2'
                      }
                    >
                      {txHelper().txText} {transaction.amount} {tokenName}
                    </span>
                  </>
                </div>
                {transaction.assetType === 'NFT' ? (
                  <>
                    {transaction.metadata.image && (
                      <div className="max-w-[32px] max-h-[32px]">
                        <img
                          src={transaction.metadata.image}
                          className="w-full h-full rounded-sm"
                          alt=""
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="text-lg text-[rgb(162,163,163)]">
                    {transaction.dollarAmount > 0
                      ? formatDollarAmount(transaction.dollarAmount)
                      : '\u00A0'}
                  </div>
                )}
              </div>
              <div className="flex justify-between">
                {transaction.receiver && (
                  <div className="flex items-center">
                    <img
                      src={transaction.receiver.profileImageUrl}
                      className="mr-[17px] w-6 h-6 rounded-full"
                      alt=""
                    />
                    <div className="text-[rgb(162,163,163)] break-all mr-2">
                      to{' '}
                      <Link to={`/@${transaction.receiver.username}`}>
                        @{transaction.receiver.username}
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="mb-8 flex items-center">
              <div className="min-w-min">
                <div className="mr-4 relative rounded-full bg-[rgb(35,36,36)] w-12 h-12">
                  <div
                    className={'h-6 w-6 absolute rounded-full right-3 top-3 ' + txHelper().style}
                  ></div>
                </div>
              </div>
              <div className="text-xl">{txHelper().text}</div>
            </div>

            {(!transaction.receiver || !transaction.receiver.claimedAt) &&
              ['CONFIRMED', 'INITIATED'].includes(transaction.status) && (
                <>
                  <div className="bg-[rgb(35,36,36)] py-6 px-5 rounded-3xl mb-8 ">
                    <div className="text-white text-2xl font-medium mb-4">
                      Is that you,{' '}
                      {transaction.receiver
                        ? `@${transaction.receiver.username}`
                        : transaction.receiverPublicKey}
                      ?
                    </div>
                    <div className="text-[rgb(162,163,163)]">
                      {transaction.sender
                        ? transaction.sender.username
                        : transaction.senderPublicKey}{' '}
                      sent some coins to your Twitter wallet. You can access your Twitter wallet by
                      verifying your account.
                    </div>
                    <br />
                    <div className="text-[rgb(162,163,163)] mb-6">
                      To get started, verify your account and activate your Twitter wallet.
                    </div>
                    <Link
                      to={
                        ['DISCORD', 'TELEGRAM'].includes(transaction.source)
                          ? '/connect'
                          : '/verify/secret'
                      }
                      className="inline-block bg-[rgb(69,70,71)] text-center pt-[18px] pb-[17px] font-medium text-2xl leading-7 rounded-[36px] w-full"
                    >
                      Activate your Wallet
                    </Link>
                  </div>
                </>
              )}
            {(!transaction.receiver || !transaction.receiver.claimedAt) && lowBalance && (
              <>
                <div className="bg-[rgb(44,45,47)] h-2 w-16 mb-8 rounded-3xl mx-auto"></div>
                <div className="bg-[rgb(35,36,36)] py-6 px-5 rounded-3xl mb-8 ">
                  <div className="text-white text-3xl mb-4">Deposit more, then try again</div>
                  <div className="text-[rgb(162,163,163)] mb-[120px]">
                    Top-up your Twitter wallet balance and then try sending this again.
                  </div>
                  <button className="bg-[rgb(69,70,71)] text-center pt-[18px] pb-[17px] font-medium text-2xl leading-7 rounded-[36px] w-full">
                    Activate your Wallet
                  </button>
                </div>
              </>
            )}
            {transaction.status === 'ERROR' && !lowBalance && (
              <>
                <div className="bg-[rgb(44,45,47)] h-2 w-16 mb-8 rounded-3xl mx-auto"></div>
                <div className="bg-[rgb(35,36,36)] py-6 px-5 rounded-3xl mb-8 ">
                  <div className="text-white text-3xl mb-4">Something went wrong</div>
                  <div className="text-[rgb(162,163,163)] mb-4">
                    Our team has been alerted and is working to prevent this from happening again.
                  </div>
                  <div className="text-[rgb(162,163,163)]">
                    You can try the transaction again or visit our{' '}
                    <a
                      href="https://discord.com/invite/7SnAX5SxTd"
                      target="_blank"
                      rel="noreferrer"
                      className="underline decoration-[rgb(162,163,163)] decoration-2 underline-offset-1"
                    >
                      Discord for help
                    </a>
                    .
                  </div>
                </div>
              </>
            )}

            {['CONFIRMED', 'INITIATED'].includes(transaction.status) && (
              <>
                <div className="bg-[rgb(35,36,36)] py-6 px-5 rounded-3xl mb-8">
                  <div className="text-[rgb(162,163,163)] mb-[27px]">Transaction Details</div>
                  <div className="mb-4">Transaction Time</div>
                  <div className="mb-6 text-[rgb(162,163,163)]">
                    {formatDate(new Date(transaction.createdAt))}
                  </div>
                  <div className="mb-4">Network Fee</div>
                  <div className="mb-6 text-[rgb(162,163,163)]">
                    {transaction.chainId === 'ETH' ? transaction.meta?.gasUsed : '0.000005 SOL'}
                  </div>
                  {transaction.commission && transaction.commission && (
                    <>
                      <div className="mb-4">Hey Wallet Fee</div>
                      <div className="mb-6 text-[rgb(162,163,163)]">
                        {transaction.commission} {transaction.asset}
                      </div>
                    </>
                  )}
                  <div className="mb-4">Signature</div>
                  <div className="mb-6 text-[rgb(162,163,163)]">{transaction.txSignature}</div>
                  {transaction.chainId === 'ETH' ? (
                    <a
                      className={`bg-[rgb(69,70,71)] pt-[18px] pb-[17px] font-medium text-md md:text-2xl leading-7 rounded-[36px] w-full flex items-center justify-center ${
                        transaction.status === 'CONFIRMED' ? '' : 'text-[rgb(23,24,24)]'
                      }`}
                      href={getTxUrl()}
                      onClick={(e) => transaction.status === 'INITIATED' && e.preventDefault()}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={polygonIcon} className="w-8 h-8 mr-4" alt="" />
                      <span className="font-medium font-lg mt-1">View on Polygonscan</span>
                    </a>
                  ) : (
                    <>
                      <a
                        className={`bg-[rgb(69,70,71)] pt-[18px] pb-[17px] font-medium text-2xl leading-7 rounded-[36px] w-full flex items-center justify-center ${
                          transaction.status === 'CONFIRMED' ? '' : 'text-[rgb(23,24,24)]'
                        }`}
                        href={getTxUrl()}
                        onClick={(e) => transaction.status === 'INITIATED' && e.preventDefault()}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={
                            transaction.status === 'CONFIRMED'
                              ? solscanActiveIcon
                              : solscanInactiveIcon
                          }
                          className="w-8 h-8 mr-4"
                          alt=""
                        />
                        <span className="font-medium font-lg mt-1">View on Solscan</span>
                      </a>
                      <a
                        className={`bg-[rgb(69,70,71)] pt-[18px] pb-[17px] font-medium text-2xl leading-7 rounded-[36px] w-full flex items-center justify-center mt-4 ${
                          transaction.status === 'CONFIRMED' ? '' : 'text-[rgb(23,24,24)]'
                        }`}
                        href={
                          transaction.status === 'CONFIRMED'
                            ? `https://solana.fm/tx/${transaction.txSignature}`
                            : '#wait'
                        }
                        onClick={(e) => transaction.status === 'INITIATED' && e.preventDefault()}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={solanaFmIcon} className="w-8 h-8 mr-4" alt="" />
                        <span className="font-medium font-lg mt-1">View on Solana.fm</span>
                      </a>
                    </>
                  )}
                  {transaction.status === 'INITIATED' && (
                    <div className="mt-6 text-[rgb(162,163,163)]/60 text-center">
                      This transaction will be available in just a moment.
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default TransactionPage
