import Secret from '../pages/auth/Secret'
import Login from '../pages/auth/Login'
import Connect from '../pages/auth/Connect'
import Welcome from '../pages/auth/Welcome'
import TwitterConnect from '../pages/auth/Twitter'
import TwitterComplete from '../pages/auth/Twitter/Complete'

const authLayoutRoutes = [
  {
    path: '/',
    redirectExternal: 'https://www.heywallet.com/',
  },
  {
    path: '/auth/secret',
    redirect: '/verify/secret',
  },
  {
    path: '/verify/secret',
    Component: Secret,
    authenticated: true,
  },
  {
    path: '/welcome',
    Component: Welcome,
    authenticated: true,
  },
  {
    path: '/auth/login',
    redirect: '/login',
  },
  {
    path: '/login',
    Component: Login,
  },
  {
    path: '/connect',
    Component: Connect,
    authenticated: true,
  },
  {
    path: '/connect/twitter',
    Component: TwitterConnect,
    // authenticated: true,
  },
  {
    path: '/connect/twitter/complete',
    Component: TwitterComplete,
    authenticated: true,
  },
]

export default authLayoutRoutes
