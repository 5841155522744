import { Helmet } from 'react-helmet'

import LayoutHeader from '../../components/LayoutHeader'

const GeneralLayout = (props) => {
  return (
    <div className="relative w-full min-h-full bg-black">
      <Helmet titleTemplate="%s" />
      <div className="flex flex-col w-full items-center overflow-y-auto overflow-x-hidden px-5">
        <LayoutHeader />
        {props.children}
      </div>
    </div>
  )
}

export default GeneralLayout
