import { Fragment } from 'react'
import { Helmet } from 'react-helmet'

const problems = [
  {
    question: 'How does this work?',
    answer: `Hey Wallet is a cryptocurrency wallet where you can send and receive crypto using tweets. After signing up and depositing crypto into your account, you can tweet something like "#heywallet send 1 SOL to @TheRock" and it will transfer 1 SOL from your account to the recipient's account (in this case, The Rock). We are currently focused on Solana because of its high speed and low fees. The average wait time for a transaction is 6 seconds. 🤯`,
  },
  {
    question: 'Can I send crypto to any twitter user?',
    answer:
      'Yes, we are permissionless, meaning that people can send crypto to any twitter account and when that account is ready to claim their funds, they can easily do before the payment claim expires.',
  },
  {
    question: 'Is this safe?',
    answer: `This is Beta software! Do not deposit anything more than you are willing to lose.`,
  },
  {
    question: 'Is this a custodial or a non-custodial wallet?',
    answer: `We will be offering both options in the future and allowing you to pick. Each comes with their own security vs user experience tradeoffs. At this time, we are only offering users a hybrid custodial solution, where you are given your private key and may recover it if you lose it. Keep the majority of your assets on a hardware wallet and only use internet-connected wallets like ours for specific use cases.`,
  },
  {
    question: 'Can I send NFTs?',
    answer: `Yes, send the NFT to your Hey Wallet, find it within your NFTs tab and then click "Send". `,
  },
  {
    question: 'When token?',
    answer: `We may or may not release a token, but here's a secret: the timestamp & order for when you activate your account will have massive consequences. That is all.`,
  },
  {
    question: 'How can I contact you?',
    answer: `Please tweet us at @hey_wallet or join our discord for help. There are a lot of scams out there, so please be careful and only use offical links provided by our twitter page @hey_wallet`,
  },
  {
    question: 'Does Hey Wallet charge a small fee?',
    answer: `Sending and receiving digital assets is currently 3%. For trading, the fee is currently set at 1% (SOL value traded). Fees are subject to change, please check back regularly for updates.`,
  },
]

const Faqs = () => {
  return (
    <div className="fade-focus-in flex w-full h-full flex-col pt-40 md:w-700px">
      <Helmet>
        <meta charSet="utf-8" />
        <title>FAQs - Hey Wallet</title>
        <meta name="description" content="The most frequently asked questions about Hey Wallet." />
      </Helmet>
      {problems.map((problem, index) => (
        <Fragment key={index}>
          <h2 className="font-colfax mb-3 text-4xl font-bold leading-none tracking-tight text-white">
            {problem.question}
          </h2>
          <h2
            className={`mb-6 text-28 font-normal leading-36px text-gray-a1a1a1 ${
              index + 1 === problems.length ? 'pb-6' : ''
            }`}
          >
            {problem.answer}
          </h2>
        </Fragment>
      ))}
    </div>
  )
}

export default Faqs
