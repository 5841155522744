import DegodsLogo from '../assets/logos/degods.svg'
import DSkullyLogo from '../assets/logos/dskully.png'
import TombstonedLogo from '../assets/logos/tombstoned.png'
import ExperimentsLogo from '../assets/logos/experiments.svg'
import HeywalletLogo from '../assets/icons/Logo.svg'

export const MainChainID = 101
export const Web3Network = 'mainnet-beta'
export const baseDomain =
  process.env.REACT_APP_ENV === 'production' ? 'heywallet.com' : 'beta.heywallet.com'

export const ApiURL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://api.heywallet.com/v1/'
    : 'https://beta-api.heywallet.com/v1/'
export const listingDomain =
  process.env.REACT_APP_ENV === 'production'
    ? 'listing.heywallet.com'
    : 'beta-listing.heywallet.com'
export const heyWalletTwitterId =
  process.env.REACT_APP_ENV === 'production' ? '1429076224756916228' : '1458913006000037889'
export const heyWalletTwitterUsername =
  process.env.REACT_APP_ENV === 'production' ? 'hey_wallet' : 'hey_wallet_beta'
export const rpcUrl = 'https://rpc.helius.xyz/?api-key=a01b48ae-81ad-4ac7-a4a2-dd4ff9dcf47e'
export const heyWalletTwitterWelcomeId =
  process.env.REACT_APP_ENV === 'production' ? '1553971845824557067' : '1550228603656228868'
export const heyWalletLoginWelcomeIdMap = {
  '/': process.env.REACT_APP_ENV === 'production' ? '1560673495444594692' : '1559733897843187716',
  '/bidding':
    process.env.REACT_APP_ENV === 'production' ? '1560673558015221764' : '1559734080551366661',
  '/connect':
    process.env.REACT_APP_ENV === 'production' ? '1572279753671061508' : '1572280603852275720',
}
export const heyWalletWhiteLabelDomains = {
  'degods.heywallet.com': { logo: DegodsLogo, welcomeId: '1564796797163282437' },
  'dskully.heywallet.com': { logo: DSkullyLogo, welcomeId: '1568332349477163012' },
  'tombstoned.heywallet.com': { logo: TombstonedLogo, welcomeId: '1568311097081450506' },
  'experiments.heywallet.com': { logo: ExperimentsLogo, welcomeId: '1600646532012843013' },
  'whitelabel.heywallet.com': { logo: HeywalletLogo, welcomeId: '1564796646403317765' },
}
export const mnemonicEncryptionKey = 'DmTBn82EKNtouBgQYQknScIJRhxOHM92'

export const oauthClientId =
  process.env.REACT_APP_ENV === 'production' ? '1017907767312388108' : '1017868477769461830'
export const oauthRedirectUri = `https://${baseDomain}/oauth`

export const alchemyKey = 'oZiq3L74hKtbzWQ-WyVm1f0xV011b-AO'
export const birdeyeKey = '12ce6eab29b748ce93971f42c8f0b1b1'
export const ethRpcUrl = `https://polygon-mainnet.g.alchemy.com/v2/${alchemyKey}`
export const telegramBotId =
  process.env.REACT_APP_ENV === 'production' ? '6103593405' : '6185575604'
export const telegramBotUsername =
  process.env.REACT_APP_ENV === 'production' ? 'heywallet_bot' : 'hw_beta_bot'

export const googleAnalyticsId = 'G-50WXK5QFCS'
export const txOneToOneCommission = 0 // In bps, so 3% value would be 300

export const swapFeeAddress = '52FYAvePTCAF5SAgQCW3ZF6RBgZcHeu9qC8eTEVaQCpz'
export const swapFeeBps = 85 // 0.85% fee
